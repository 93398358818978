import dateFormat from "dateformat"
import { numberFormatter } from "./numbers"

export function now() {
    return smrtLinkDateFormat(new Date())
}

export function nowTime() {
    return dateFormat(new Date(), "hh:MM:ss TT")
}

export function nowForFileNames() {
    return dateFormat(new Date(), "yyyy-mm-dd__MM-ss-TT")
}

export function smrtLinkDateFormat(d: Date, showSeconds: boolean = true): string {
    if (String(d) !== "Invalid Date") {
        return dateFormat(d, `yyyy-mm-dd, hh:MM${showSeconds ? ":ss" : ""} TT`)
    } else {
        return ""
    }
}

export function smrtLinkDateFormatFromString(s: string) {
    const d = new Date(s)
    return smrtLinkDateFormat(d)
}


export function smrtLinkTimeFormat(d: Date): string {
    if (String(d) !== "Invalid Date") {
        return dateFormat(d, "hh:MM:ss TT")
    } else {
        return ""
    }
}

export const nowPlusSeconds = (seconds: number): Date => {
    const now = new Date()
    const nowMs = now.valueOf()
    return new Date(nowMs + 1000 * seconds)
}

export const minAndSec = (seconds: number) => {
    const min = Math.floor(seconds/60)
    const sec = seconds % 60
    let minSec = ""
    if (min) {
        minSec += `${min}min `
    }
    minSec += `${sec}s`
    return minSec
}

export const hours = ( seconds: number, precision: number) => {
    const h = seconds / (60*60)
    return `${numberFormatter(h, precision)}h`
}

export const durationString = (sec: number): string => {

    const secsPerDay = 60 * 60 * 24
    const days =  Math.floor(sec / secsPerDay)
    const daysClause = days > 0 ?
        (days >= 2 ? `${days} days` : `${days} day`) : ""

    let minutes = Math.ceil(sec / 60)
    let hours = (minutes / 60) % 24
    let hoursClause = ""
    if (days >= 1) {
        hours = Math.round(hours)
        hoursClause = hours === 1 ? `${hours}h` : `${hours}h`
        return `${daysClause} and ${hoursClause}`
    } else {
        if ( hours < 1) {
            return `${minutes}m`
        } else {
            hours = Math.floor(hours)
            minutes = minutes % 60
            return `${hours}h ${minutes}m`
        }
    }
}
