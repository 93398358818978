import { SampleObject } from "../../model/sample-setup-model"

export const SampleSetupActionTypes = {
  API_ATTEMPT: "@@sample-setup/API_ATTEMPT",
  API_FAILURE: "@@sample-setup/API_FAILURE",
  GET_SAMPLE_LIST_SUCCESS: "@@sample-setup/GET_SAMPLE_LIST_SUCCESS",
}

export interface SampleApiState {
  readonly loading: boolean
  readonly data: SampleObject[]
  readonly error: Error | null
}

