import { ColumnState } from "ag-grid-community"

interface ColumnSortModel {
    colId: string
    sort: "asc" | "desc"
}

interface ColumnFilterModel {
    filterType: string,
    type: string,
    filter: string
}

export type SortModel = ColumnSortModel[]
export type FilterModel = { [key:string]:ColumnFilterModel; }

export interface StoredGridState {
    colState: ColumnState[]
}

export const storeGridState = (gridApi: any, key: string): void => {
    if (!gridApi) { return }
    if (!key) { return }
    const gridStateJson = JSON.stringify({
        colState: gridApi.getColumnState()
    })
    sessionStorage.setItem(key, gridStateJson)
}

export const retrieveGridState = (key: string): StoredGridState => {
    const gridStateJson = sessionStorage.getItem(key)
    if (!gridStateJson) { return null}
    return JSON.parse(gridStateJson)
}
