import React from "react"
import { useHistory } from "react-router-dom"

export const LinkWithoutReload = (url: string, name: string) => {
    const history = useHistory()
    const extraSL = "/sl/"
    return <span>
        <a href={url} style={{cursor: "pointer"}} onClick={event => {
            if (url && url.startsWith(extraSL)) { url = url.substring(extraSL.length - 1) }
            history.push(url)
            event.preventDefault()
        }}>{name}</a>
    </span>
}

export const BrandImageLinkWithoutReload = (url) => {
    const history = useHistory()
    return <a href={url}
        style={{cursor: "pointer"}}
        title="Pacific Biosciences"
        className="navbar-brand"
        onClick={event => {
            history.push(url)
            event.preventDefault()
        }
    }>
        <img
            src={`${process.env.PUBLIC_URL}/img/pb-logo.svg`}
            width="auto"
            height="30"
            alt="Pacific Biosciences logo"
        />
    </a>
}
