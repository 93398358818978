import { ActionCreator, Action } from "redux"
import { ThunkResult, ApplicationState } from "../../../core/types"
import { setIsFreeMode } from "src/core/store/core-actions"
import { ThunkDispatch } from "redux-thunk"
import { Tenant } from "src/data/model/tenant-model"
import { TenantActionTypes } from "./tenant-types"
import * as API from "src/data/api"

export const fetchTenant = (): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>,
    getState: () => ApplicationState
) => {
    if (API.isApiV3()) {
        dispatch(apiAttempt())
        try {
            const { data: tenant } = await API.getTenant()
            const { isDebugFreeMode } = getState().dev
            dispatch(setTenant(tenant))
            dispatch(setIsFreeMode(isDebugFreeMode, tenant?.subscriptionType))
        } catch (err) {
            dispatch(apiFailure(err))
        }
    }
}

export const setTenant: ActionCreator<Action> = (tenant: Tenant) => ({
    type: TenantActionTypes.SET_TENANT,
    payload: tenant
})

export const apiAttempt: ActionCreator<Action> = () => ({
    type: TenantActionTypes.API_ATTEMPT
})

export const apiFailure: ActionCreator<Action> = (error) => ({
    type: TenantActionTypes.API_FAILURE,
    payload: error
})

