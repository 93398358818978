import { allRevioWells, blankSample, maxMovieTime, maxMovieTimeInternal, maxMovieTimeVegaInternal, minMovieTime, minMovieTimeInternal, minMovieTimeVegaInternal, movieTimesRevio, movieTimesVega, plateBarcodeOneReaction, revioDefaults, RunForm, vegaDefaults } from "../constants/constants"
import { v4 } from "uuid"
import { runSummaryLink } from "../../../core/Routes"
import { getRecommendedPreextensionTime } from "./pre-extension"
import { isEqual, cloneDeep } from "lodash"
import { PartsService } from "../../../data/model/parts-model"
import { Application, RunModel } from "../../../data/model/run-model"
import { InstrumentType, isRevio, isRevioOrVega, isVega } from "../../../data/model/instrument-type-model"

export const replaceField = (field, newValue, sample, index, replace) => {
    let newSample = Object.assign({}, sample)
    newSample[field] = newValue
    replace(index, newSample)
    return newSample
}

export const viewSummary = (history, runUuid) => {
    history.push(runSummaryLink(runUuid))
}

export const toggleSampleView = (index: number, sample: any, replace: any) => {
    replaceField("isSampleOpen", !sample.isSampleOpen, sample, index, replace)
}

export const toggleAdvancedView = (index: number, sample: any, replace: any) => {
    replaceField("isAdvancedOpen", !sample.isAdvancedOpen, sample, index, replace)
}

export const toggleRunOptionsView = (index: number, sample: any, replace: any) => {
    replaceField("isRunOptionsOpen", !sample.isRunOptionsOpen, sample, index, replace)
}

export const toggleAnalysisView = (index: number, sample: any, replace: any) => {
    replaceField("isAnalysisOpen", !sample.isAnalysisOpen, sample, index, replace)
}

export const toggleBarcodeView = (index: number, sample: any, replace: any) => {
    replaceField("isBarcodeOpen", !sample.isBarcodeOpen, sample, index, replace)
}

export const toggleAdvancedBarcodeView = (index: number, sample: any, replace: any) => {
    replaceField("isAdvancedBarcodeOpen", !sample.isAdvancedBarcodeOpen, sample, index, replace)
}

export const getImmobilizationTimes = () => {
    return [1, 2, 4]
}

export const getMovieTimes = (s: string) => {
    return isRevio(s) ? movieTimesRevio : movieTimesVega
}

export const hoursToMinutes = (value): number => {
    return value * 60
}

export const minutesToHours = (value): number => {
    return value / 60
}

export const secondsToHours = (value): number => {
    return value / 3600
}

export const radioToBoolean = (value: string): boolean => {
    return value === "YES" ? true : false
}

const pickNewWell = (newSample, runDesignForm: RunForm) => {
    let availableWells = allRevioWells.filter(well =>
        !runDesignForm.assignedWells.includes(well) &&
        !runDesignForm.usedWells.includes(well))
    if (plateBarcodeOneReaction.includes(runDesignForm.plate1Barcode)) {
        availableWells = availableWells.filter(well =>
            !["1_B01", "1_C01", "1_D01"].includes(well)
        )
    }
    if (plateBarcodeOneReaction.includes(runDesignForm.plate2Barcode)) {
        availableWells = availableWells.filter(well =>
            !["2_B01", "2_C01", "2_D01"].includes(well)
        )
    }
    if (availableWells.length > 0) {
        let newPlateWell = availableWells[0]
        newSample.plateNumber = newPlateWell.split("_")[0]
        newSample.wellName = newPlateWell.split("_")[1]
        newSample.plateWell =newPlateWell
        return newSample
    }
    return null
}

export const updateAssignedWells = (newAssignedWells, runDesignForm, setFieldValue) => {
    let assignedWells = runDesignForm.assignedWells.concat(newAssignedWells)
    setFieldValue("assignedWells", assignedWells.sort())
}

// TODO: mcantor 6.17.24, this method is dangerous.  If we add a sample property
// we are likely to forget to explicitly copy over this properly from sample to newSample.
// Should be refactored to avoid the need to call out individual properties.
export const resetSample = (sample, systemName) => {
    let defaults = isVega(systemName) ? vegaDefaults : revioDefaults
    let newSample = Object.assign({}, defaults)
    newSample.application = sample.application
    newSample.id = sample.id
    newSample.ccsUuid = sample.ccsUuid
    newSample.sampleName = sample.sampleName
    newSample.wellName = sample.wellName
    newSample.insertSize = sample.insertSize
    newSample.plateNumber = sample.plateNumber
    newSample.plateWell = sample.plateWell
    newSample.sampleDescription = sample.sampleDescription
    newSample.insertSize = sample.insertSize
    newSample.onPlateLoadingConcentration = sample.onPlateLoadingConcentration
    return newSample
}

export const setDefaults = (sample, instrumentType) => {
    let defaults = isVega(instrumentType) ? vegaDefaults : revioDefaults
    Object.keys(blankSample).forEach(key => {
        sample[key] = defaults[key]
    })
}

export const Create = (runDesignForm, systemName, setFieldValue) => {
    let newSample = Object.assign({}, blankSample)
    setDefaults(newSample, systemName)
    newSample = pickNewWell(newSample, runDesignForm)
    updateAssignedWells([newSample.plateWell], runDesignForm, setFieldValue)
    newSample.id = v4()
    newSample.ccsUuid = v4()
    setFieldValue("samples", runDesignForm.samples.concat(newSample))
}

export const Copy = (push, sample, runDesignForm, systemName, setFieldValue) => {
    let newSample = Object.assign({}, sample)
    newSample = pickNewWell(newSample, runDesignForm)
    updateAssignedWells([newSample.plateWell], runDesignForm, setFieldValue)
    newSample.id = v4()
    newSample.ccsUuid = v4()
    newSample.multiJobId = 0
    newSample.barcodeUuidMap = {}
    Object.keys(sample.barcodeUuidMap).forEach(barcode => {
        newSample.barcodeUuidMap[barcode] = v4()
    })
    push(newSample)
}

export const canAddSample = (values: RunForm) => {
    let newSample = Object.assign({}, blankSample)
    return Boolean(pickNewWell(newSample, values))
}

export const Delete = (remove, index, runDesignForm, setFieldValue) => {
    let assignedWells = runDesignForm.assignedWells.filter(
        well => well !== runDesignForm.samples[index].plateWell)
    setFieldValue("assignedWells", assignedWells)
    remove(index)
}

export const getPairs = (array) => {
    let pairs = []
    for (let i = 0; i < array.length - 1; i++) {
        for (let j = i; j < array.length - 1; j++) {
            pairs.push([array[i], array[j + 1]])
        }
    }
    return pairs
}

export const getColor = (applicationDefaults: Application, systemName, sample, field) => {
    if (applicationDefaults) {
        const defaults = isRevioOrVega(systemName) ? applicationDefaults.systems.Revio : applicationDefaults.systems.Sequel2
        if (defaults && defaults[field]) {
            return defaults[field] === sample[field] ? "palegreen" : "yellow"
        }
    }
    return "white"
}

export const getKitColor = (applicationDefaults: Application, systemName, sample, field, partsService) => {
    if (isRevioOrVega(systemName)) {
        return "white"
    }
    if (applicationDefaults && partsService) {
        const defaults = applicationDefaults.systems.Sequel2
        let defaultKit = partsService.parseBarcode(defaults[field]) ? partsService.parseBarcode(defaults[field]).partNumber : null
        let kit = partsService.parseBarcode(sample[field]) ? partsService.parseBarcode(sample[field]).partNumber : null
        if (defaultKit && defaultKit === kit) {
            return "palegreen"
        } else if (defaultKit) {
            return "yellow"
        } else {
            return "white"
        }
    } else {
        return "white"
    }
}

export const getExtensionTimeColor = (applicationDefaults: Application, systemName: InstrumentType, sample, field, appConfig) => {
    if (applicationDefaults) {
        const defaults = isRevioOrVega(systemName) ? applicationDefaults.systems.Revio : applicationDefaults.systems.Sequel2
        const recommendedExtensionTime = defaults[field] === "formula"
            ? getRecommendedPreextensionTime(sample.insertSize, "NO", systemName, appConfig, sample.application)
            : defaults[field]
        if (applicationDefaults.title === "Custom") {
            return "white"
        }
        if (!recommendedExtensionTime || !(recommendedExtensionTime.length === 0) || isNaN(Number(recommendedExtensionTime))) {
            return "yellow"
        }
        if (Number(recommendedExtensionTime) ===  Number(sample[field])) {
            return "palegreen"
        } else {
            return "yellow"
        }
    } else {
        return "white"
    }
}

export const booleanToString = (value) => {
    return value ? "TRUE" : "FALSE"
}

export const formatDecimal = (number) => {
    const locale = localStorage.getItem("decimalSeparator") === "," ? "es-ES" : "en-us"
    let formatter = new Intl.NumberFormat(locale)
    return formatter.format(number)
}

export const isDirty = (values: RunForm, initialValues: RunForm) => {
    const blacklist =
        ["isSampleOpen", "isAdvancedOpen", "isBarcodeOpen", "isAdvancedBarcodeOpen",
        "barcodeSetName", "isValidBarcodeDesign", "isRunOptionsOpen", "isAnalysisOpen"]
    let valuesC = cloneDeep(values)
    let initialValuesC = cloneDeep(initialValues)
    blacklist.forEach(field => {
        valuesC.samples.forEach(sample => {
            delete sample[field]
        })
        initialValuesC.samples.forEach(sample => {
            delete sample[field]
        })
    })
    return !isEqual(valuesC, initialValuesC)
}

export const getDate = (dateString: string) => {
    return new Date(
        Number(dateString.slice(0,4)),
        Number(dateString.slice(4,6))-1,
        Number(dateString.slice(6,8)))
}

export const isExpired = (expirationDate: Date) => {
    const milliSecondsInDay = 60*60*24*1000
    return expirationDate.getTime() + milliSecondsInDay < Date.now()
}


export const getExpirationDate = (barcode: string) => {
    const dateString = getExpiry(barcode)
    return getDate(dateString)
}

export const getLot = (barcode: string) => {
    return barcode.slice(9,15)
}

export const getExpiry = (barcode: string) => {
    return barcode.slice(20,28)
}

export const getPartNumber = (barcode: string) => {
    const partNumberBarcode = getPartNumberString(barcode)
    return partNumberBarcode.slice(0,3) + "-" +
           partNumberBarcode.slice(3,6) + "-" +
           partNumberBarcode.slice(6,9)
}

export const getPartNumberString = (barcode: string) => {
    return barcode.slice(0,9)
}

export const getOldBarcode = (barcode: string) => {
    return barcode.slice(9,15) +
           barcode.slice(0,9) +
           barcode.slice(24,28) +
           barcode.slice(22,24)
}

// old format
// 123456 102118800 123123
// lot number + part number + exp date

// new format
// 102118800 123456 12345 20231231
// part number + lot number + label number + exp date

export const makeNewBarcode = (barcode: string, labelNumber: string) => {
    return barcode.slice(6,15) +
           barcode.slice(0,6) +
           labelNumber +
           "20" +
           barcode.slice(19,21) +
           barcode.slice(15,19)
}

export const getPlateBarcode = (run: RunModel, plateNumber: number) => {
    return plateNumber === 1 ? run.plate1Barcode : run.plate2Barcode
}

export const getLabelNumber = (barcode: string) => {
    return barcode.slice(15,20)
}

export const getLabel = (barcode: string, partsService: PartsService) => {
    let partNumber = getPartNumber(barcode)
    let part = partsService.getPart(partNumber)
    if (part) {return part.name} else {return ""}
}

export const makeOldBarcode = (partNumber: string) => {
    return "Lxxxxx" + partNumber.replace(new RegExp("-", "g"), "") + "123199"
}

export const resetAvailableWells = (e, sample, props) => {
    let plateWellId = e.target.value
    if (plateWellId) {
        let assignedWells =
        props.values.assignedWells.concat([plateWellId])
        assignedWells =
        assignedWells.filter(well => well !== sample.plateWell)
        props.setFieldValue(
            "assignedWells", assignedWells.sort())
    }
}

export const disablePlateWell = (id, values, sample) => {
    return (
        (values.assignedWells.includes(id) && id !== sample.plateWell) ||
        (plateBarcodeOneReaction.includes(values.plate1PartNumber) && ["1_B01", "1_C01", "1_D01"].includes(id)) ||
        (plateBarcodeOneReaction.includes(values.plate2PartNumber) && ["2_B01", "2_C01", "2_D01"].includes(id))
    )
}

export const getMinMovieTime = (systemName: InstrumentType, isInternalModeEnabled: boolean) => {
    if (isInternalModeEnabled) {
        return isVega(systemName) ? minMovieTimeVegaInternal : minMovieTimeInternal
    } else {
        return minMovieTime
    }
}

export const getMaxMovieTime = (systemName: InstrumentType, isInternalModeEnabled: boolean) => {
    if (isInternalModeEnabled) {
        return isVega(systemName) ? maxMovieTimeVegaInternal : maxMovieTimeInternal
    } else {
        return maxMovieTime
    }
}
