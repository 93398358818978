import { create, ApiResponse, ApisauceConfig, ApisauceInstance, ApiErrorResponse, HEADERS } from "apisauce"
import { authConfig } from "../core/config"
import { AuthToken, TokenResponse, UserResponse } from "../core-components/login/store/login-types"
import { RunQcObject, RunQcSampleObject } from "./model/run-qc-model"
import { SampleObject } from "./model/sample-setup-model"
import { Job, JobEvent, JobEntryPoint, JobStatusEvent } from "./model/job-model"
import { Report } from "./model/report-model"
import { User, KeycloakUser, KeycloakUserRole } from "./model/user-model"
import { AdminServiceV2 } from "./utils/configuration/admin-service-v2"
import { MultiJobOptions, JobOptions, DeleteJobOptions, ExportAnalysisJobOptions, AnyJobOptions } from "./model/job-options-model"
import { DatastoreFile } from "./model/datastore-file-model"
import { PipelineTemplate } from "./model/pipeline-template-models"
import { ServerBarcodeResponse, ServerBarcode } from "./model/ui-run-model"
import { PipelineDataStoreViewRule } from "./model/pipeline-datastore-view-rules-model"
import { ReportViewRule } from "./model/report-view-rules.model"
import { Project } from "./model/project-model"
import { FolderModel } from "./model/ls-model"
import { clearToken, refreshToken, apiFailure } from "../core-components/login/store/login-actions"
import { ApplicationState } from "../core/types"
import { Store } from "redux"
import { Dataset } from "./model/dataset-model"
import { reportError } from "../core-components/shared/Error/ErrorBoundary"
import { DesignObject, DesignModel, ApplicationsResponse } from "./model/run-model"
import { PipelineViewRule } from "./model/pipeline-template-view-rules-model"
import { SampleSetupApplications } from "../silos/sample-setup/utils/binding-calculator/sample-calculator-params-model"
import { AlarmModel } from "./model/alarm-model"
import { ComponentVersionInfo } from "./services/version-manifest-service"
import { Eula } from "./services/eula-service"
import { WorkflowPreset } from "./model/workflow-preset-model"
import { LoggerOptions } from "./services/logger-service"
import { RunDesign, RunSummary } from "./model/run-design-model"
import { NotificationModel } from "./model/notification-model"
import { jsonToUserPreferences, UserPreferences, userPreferencesToJson } from "./model/user-preferences-model"
import { InstrumentConnection, InstrumentConnectionCreate, InstrumentConnectionPartial } from "./model/instrument-connection-model"
import { FailedTransfer, FailedTransfersData, Instrument, InstrumentRecord } from "./model/instrument-model"
import {
    FileTransferLocation,
    ICSFileTransferLocation,
    FileTransferLocationPartial,
    InstrumentSummary,
    TransferSchemeTest
} from "./model/instrument-configuration-model"
import { appConfig } from "../core/config/app-config"
import { SmrtLinkStatus } from "./model/status-model"
import { TemplateFile, TemplateFilePostOptions } from "./model/template-file"
import { DatastoreReportFile } from "./model/datastore-report-file-model"
import { Collection } from "./model/collection-model"
import { Tenant } from "./model/tenant-model"
import { UiDatastoreFile } from "./model/ui-datastore-file"

export const NULL_PARAM = "null"
export const NOT_FOUND_ERROR_TYPE = "Not Found"
export const API_VERSION_H = "x-api-version"
const API_VERSION_1 = "1.0.0"
const API_VERSION_2 = "2.0.0"
const API_VERSION_3 = "3.0.0"
const DEFAULT_VERSION = API_VERSION_2

export enum ERROR_MODE {
    default = "default",
    custom = "custom"
}
export class ResourceNotFoundError extends Error {
    constructor(message?: string) {
        super(message)
        Object.setPrototypeOf(this, new.target.prototype)
        this.name = ResourceNotFoundError.name // unreliable, differs between localhost and production envs
    }
}

class RefreshService {

    // Ensure only one refresh attempt at a time -- multiple API calls can wait on the same promise
    refreshAuthTokenPromise: Promise<void> | null = null

    private hasCachedRefreshToken: boolean = false

    // This array could be rethought in terms of promises...
    apiCallsToRetryAfterLogin: DeferredAPICall[] = []

    private refreshSucceeded: () => void = null

    getRefreshPromise() {
        if (!store) {
            return Promise.reject("No store")
        }

        if (!this.refreshAuthTokenPromise) {
            this.refreshAuthTokenPromise = new Promise((resolve) => {
                this.refreshSucceeded = resolve
                store.dispatch(refreshToken(error => {
                    if (error) {
                        // Ignore errors -- the UI will try again
                        return
                    } else {
                        this.refreshSucceeded = null
                        resolve()
                        // Clear promise for RefreshService in a few seconds.
                        // Use a timeout to let any other outstanding network requests have a change to retry.
                        // It is possible this can be for a much shorter delay, even just a few ms?
                        setTimeout(() => {
                            this.refreshAuthTokenPromise = null
                        },         5000)
                    }
                }))
            })
        }

        return this.refreshAuthTokenPromise
    }

    setHasCachedRefreshToken(hasRefreshToken: boolean) {
        return this.hasCachedRefreshToken = hasRefreshToken
    }

    getHasCachedRefreshToken() {
        return this.hasCachedRefreshToken
    }

    retryDeferredAPICalls() {
        if (this.refreshSucceeded) { this.refreshSucceeded() }
        for (let apiCall of this.apiCallsToRetryAfterLogin) {
            // apiCall.api[apiCall.method](...apiCall.args)
            apiCall.api._getDelayedCall(apiCall.method, ...apiCall.args).then(apiCall.resolve, apiCall.reject)
        }
        this.apiCallsToRetryAfterLogin = []
        this.refreshSucceeded = null
        this.refreshAuthTokenPromise = null
    }
}

export const refreshService = new RefreshService()

interface DeferredAPICall  {
    api: API
    method: string
    args: any[]
    resolve: (value?: unknown) => void
    reject: (reason?: any) => void
}

export class API {
    private api: ApisauceInstance
    private version: string

    constructor(config: ApisauceConfig) {
        this.api = create(config)
    }

    setVersion(apiVersion: string) {
        this.version = (apiVersion === undefined) ? DEFAULT_VERSION : apiVersion
        localStorage.setItem("API_VERSION", apiVersion)
    }

    getVersion() {
        if (this.version === undefined) {
            return localStorage.getItem("API_VERSION") || DEFAULT_VERSION
        } else {
            return this.version
        }
    }

    _getDelayedCall(method: string, ...args) {
        // Check first if have a refresh token and otherwise wait until login is done
        if (args[0] !== "token" && !refreshService.getHasCachedRefreshToken()) {
            // no refresh token, deferring non-token-fetching api call until logged in
            return new Promise((resolve, reject) => {
                refreshService.apiCallsToRetryAfterLogin.push({api: this, method, args, resolve, reject})
            })
        }

        return this._directCall(method, ...args).then(response => {
            if (response.ok) { return response }
            if (response.status === 401) {
                return refreshService.getRefreshPromise().then(() => {
                    // Try the API call one more time...
                    return this._directCall(method, ...args)
                })
            } else {
                return response
            }
        }).catch(error => {
            if (error.status === 400) {
                // BAD REFRESH TOKEN -- need to login again
                return new Promise((resolve, reject) => {
                    refreshService.apiCallsToRetryAfterLogin.push({api: this, method, args, resolve, reject})
                })
            }  else {
                throw error
            }
        })
    }

    setHeader(key: string, value: string) {
        this.api.setHeader(key, value)
    }

    _directCall(method: string, ...args) {
        return this.api[method](...args)
    }

    makeCall(method: string, ...args) {

        return this._getDelayedCall(method, ...args)
            .then(response => {
                const { ok, data, problem } = response

                if (!ok) {
                    switch (problem) {
                        case "CLIENT_ERROR":
                            response.data = {
                                status: "error",
                                ...data
                            }

                            if (response.status === 401) {
                                if (response.data.name === "RefreshTokenError") {
                                    store.dispatch(clearToken())
                                    return Promise.reject(response)
                                } else {
                                    const serverMessage = response.data.message ?
                                        response.data.message : "You are not authorized"
                                    response.data = {
                                        status: "error",
                                        name: "Unauthorized",
                                        message: serverMessage,
                                        ...data
                                    }

                                    const err = new Error(serverMessage)
                                    err.name = "Unauthorized"
                                    err["response"] = response
                                    if (args[1] !== ERROR_MODE.custom) {
                                        reportError(err)
                                    }
                                    return Promise.reject(response)
                                }
                            } else if (response.status === 400 && response.data.error === "invalid_grant") {
                                store.dispatch(clearToken())
                                return Promise.reject(response)
                            }  else if (response.status === 404) {
                                if (data.errorType === NOT_FOUND_ERROR_TYPE) {
                                    throw new ResourceNotFoundError(data.message)
                                }
                                return Promise.reject(response)
                            }
                            return Promise.reject(response)

                        case "TIMEOUT_ERROR":
                            if (!response.status) { response.status = 408 }
                            response.data = {
                                status: "error",
                                message: "Network timeout. Please try again.",
                                ...data
                            }
                            return Promise.reject(response)

                        case "CONNECTION_ERROR":
                            if (!response.status) { response.status = 503 }
                            response.data = {
                                status: "error",
                                message: "Server not available.",
                                ...data
                            }
                            return Promise.reject(response)

                        case "NETWORK_ERROR":
                            // TODO(pfernhout) Fix AWS workaround
                            if (isApiV3()) {
                                // Ignore CORS errors for now
                                return Promise.resolve({ok: false, data: [], notifications: []})
                            }
                            if (!response.status) { response.status = 511 }
                            response.data = {
                                status: "error",
                                message: "Network unavailable.",
                                ...data
                            }
                            return Promise.reject(response)

                        case "CANCEL_ERROR":
                            if (!response.status) { response.status = 499 }
                            response.data = {
                                status: "error",
                                message: "Request has been cancelled.",
                                ...data
                            }
                            return Promise.reject(response)

                        default:
                            return Promise.reject(response)
                    }
                }

                return Promise.resolve(response)
            })
        }
}

const isDevMode = process.env.REACT_APP_IS_DEV_MODE === "true"

export const slServer = isDevMode ?
    process.env.REACT_APP_SL_SERVER :
    window.location.protocol + "//" + window.location.host

const defaultApiConfig = {
    baseURL: slServer,
    timeout: 30000
}

const slApi = new API(defaultApiConfig)

const slTokenApi = new API({
    ...defaultApiConfig,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
})

const slXmlApi = new API({
    ...defaultApiConfig,
    headers: {
        Accept: "text/xml",
        "Content-Type": "application/json"
    }
})

const slImageApi = new API({
    ...defaultApiConfig,
    headers: {
        Accept: "image/*,*/*;q=0.8'",
        "Content-Type": "application/json"
    },
    responseType: "blob"
})

const slDownloadApi = new API({
    ...defaultApiConfig,
    headers: {
        Accept: "application/octet-stream",
        "Access-Control-Expose-Headers": "Content-Disposition",
    },
    responseType: "blob"
})

const slUploadApi = new API({
    ...defaultApiConfig,
    headers: {
        // Accept: null,
        "Content-Type": "application/json"

    }
})

const slJsonApi = new API({
    ...defaultApiConfig,
    headers: {
        "Content-Type": "application/json"
    }
})

const slBarcodeApi = new API({
    ...defaultApiConfig,
    headers: {
        Accept: "text/plain"
    }
})

const slKeycloakApi = new API({
    ...defaultApiConfig,
    headers: {
        "Content-Type": "application/json"
    }
})

const slApis = [slApi, slTokenApi, slXmlApi, slImageApi, slUploadApi, slJsonApi, slBarcodeApi, slKeycloakApi, slDownloadApi]
let store: Store<ApplicationState, any> = null

const apiVersion = (process.env.REACT_APP_API_VERSION === undefined) ? API_VERSION_1 : process.env.REACT_APP_API_VERSION
const clientId = process.env.REACT_APP_CLIENT_ID
const cognitoURL = process.env.REACT_APP_COGNITO_URL
const ssoURL = process.env.REACT_APP_SSO_URL
const useSSO = (process.env.REACT_APP_USE_SSO === undefined) ? false : process.env.REACT_APP_USE_SSO === "true"
const allowLocalLogin = (process.env.REACT_APP_ALLOW_LOCAL_LOGIN === undefined) ? false : process.env.REACT_APP_ALLOW_LOCAL_LOGIN === "true"
const forcePbOkta = (process.env.REACT_APP_FORCE_PBOKTA === undefined) ? false : process.env.REACT_APP_FORCE_PBOKTA === "true"
// this is on by default, but can be selectively disabled at build time
export const enableVega = (process.env.REACT_APP_ENABLE_VEGA === undefined) ? true : process.env.REACT_APP_ENABLE_VEGA !== "false"

export function getSSOLoginURL() {
    if (!useSSO) { return "" }
    const scope = "aws.cognito.signin.user.admin email openid profile tropos/analysis tropos/data-management " +
        "tropos/instruments tropos/pb-instrument tropos/runs tropos/sample-setup tropos/smrtlink-admin"
    if (getForcePbOkta()) {
        return `${ssoURL}/oauth2/authorize?identity_provider=pbokta&redirect_uri=${window.location.origin}/authenticated` +
        `&response_type=CODE&client_id=${clientId}` +
        `&scope=${scope}`
     }
    return `${ssoURL}/login?response_type=code&client_id=${clientId}` +
     `&redirect_uri=${window.location.origin}/authenticated`
}

export const globalCognitoLogout = async (accessToken: string) => {
    await fetch(cognitoURL, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.GlobalSignOut"
        },
        body: JSON.stringify({ AccessToken: accessToken })
    })
}

export function getUseSSO() {
    return useSSO
}

export function getAllowLocalLogin() {
    return allowLocalLogin || !useSSO || !ssoURL
}

export function getForcePbOkta() {
    return forcePbOkta
}

export const prefix = `/SMRTLink/${apiVersion}`
export const prefix2 = `/SMRTLink/${API_VERSION_2}`
export const instrumentPrefix = `${prefix}/ics`

export const adminServiceV2: AdminServiceV2 = new AdminServiceV2()

export const getAdminService = () => {
    return adminServiceV2
}

export const configureApi = (appStore: Store<ApplicationState, any>) => store = appStore

export const isApiV3 = () => {
    return apiVersion === API_VERSION_3
}
export const isVegaSupported = () => {
    return enableVega
}

export const getCloudToken = async ({
    username,
    password,
    // grant_type,
    // scope,
    refresh_token
}: any): Promise<ApiResponse<TokenResponse>> => {

    const cognitoHeaders = {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1"
    }

    let data;

    if (refresh_token) {
        data = {
            AuthParameters: {
                REFRESH_TOKEN: refresh_token
            },
            AuthFlow: "REFRESH_TOKEN_AUTH",
            ClientId: clientId,
        }
    } else {
        data = {
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password
            },
            AuthFlow: "USER_PASSWORD_AUTH",
            ClientId: clientId,
        }
    }

    // Use fetch as we are not doing normal authenticated API call
    const response = await fetch(cognitoURL, {method: "POST", headers: cognitoHeaders, body: JSON.stringify(data)})
    let jsonData = null
    try {
        jsonData = await response.json()
    } catch (error) {
        return {
            ok: false,
            headers: response.headers as unknown as HEADERS,
            problem: null,
            originalError: null,
            data: {
                error: "CognitoError",
                error_description: "Error processing Cognito response",
                message: "Error processing Cognito response",
                status: "CognitoErrorException",
                access_token: null,
                refresh_token: null,
                id_token: null,
                expires_in: 0,
                scope: "",
                token_type: ""
            }
        }
    }

    if (jsonData.__type === "NotAuthorizedException") {
        return {
            ok: false,
            headers: response.headers as unknown as HEADERS,
            problem: null,
            originalError: null,
            data: {
                error: "NotAuthorizedException",
                error_description: "NotAuthorizedException",
                message: "NotAuthorizedException",
                status: "NotAuthorizedException",
                access_token: null,
                refresh_token: null,
                id_token: null,
                expires_in: 0,
                scope: "",
                token_type: ""
            }
        }
    }

    if (jsonData.__type === "ResourceNotFoundException") {
        return {
            ok: false,
            headers: response.headers as unknown as HEADERS,
            problem: null,
            originalError: null,
            data: {
                error: "ResourceNotFoundException",
                error_description: "ResourceNotFoundException",
                message: "ResourceNotFoundException",
                status: "ResourceNotFoundException",
                access_token: null,
                refresh_token: null,
                id_token: null,
                expires_in: 0,
                scope: "",
                token_type: ""
            }
        }
    }

    if (!jsonData.AuthenticationResult) {
        return {
            ok: false,
            headers: response.headers as unknown as HEADERS,
            problem: null,
            originalError: null,
            data: {
                error: "NoAuthenticationResultException",
                error_description: "NoAuthenticationResultException",
                message: "NoAuthenticationResultException",
                status: "NoAuthenticationResultException",
                access_token: null,
                refresh_token: null,
                id_token: null,
                expires_in: 0,
                scope: "",
                token_type: ""
            }
        }
    }

    return {
        ok: true,
        headers: response.headers as unknown as HEADERS,
        problem: null,
        originalError: null,
        data: {
            error: "",
            error_description: "",
            message: "",
            status: "",
            access_token: jsonData.AuthenticationResult.AccessToken,
            refresh_token: refresh_token ? refresh_token : jsonData.AuthenticationResult.RefreshToken,
            id_token: jsonData.AuthenticationResult.IdToken,
            expires_in: jsonData.AuthenticationResult.ExpiresIn,
            scope: "",
            token_type: ""
        }
    }
}

export const getToken = async ({
    username,
    password,
    grant_type,
    scope,
    refresh_token,
    code
}: any): Promise<ApiResponse<TokenResponse>> => {
    const authKey = btoa(`${authConfig.consumerKey}:${authConfig.consumerSecret}`)
    slApis.forEach(api => {
        api.setHeader("Authorization", `Basic ${authKey}`)
    })

    if (isApiV3() && useSSO && code) {
        const escapedStr = `code=${code}&grant_type=authorization_code&client_id=${clientId}` +
            `&redirect_uri=${window.location.origin}/authenticated`
        // Use fetch as we are not authenticated yet but want to be
        const fetchResult = await fetch(ssoURL + "/oauth2/token", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: escapedStr
        })

        const result = await fetchResult.json()

        const { error } = result

        if (!error && result) {
            const refresh_token = result.refresh_token
            return getCloudToken({grant_type, scope, refresh_token, code})
        } else {
            store.dispatch(apiFailure("SSO login issue"))
            return null
        }
    } else if (isApiV3()) {
        return getCloudToken({username, password, grant_type, scope, refresh_token})
    }

    const escapedStr = refresh_token
        ? `grant_type=refresh_token&refresh_token=${refresh_token}`
        : `grant_type=${encodeURIComponent(grant_type)}&username=${encodeURIComponent(username)}&` +
          `password=${encodeURIComponent(password)}&scope=${encodeURIComponent(scope)}`

    return slTokenApi._directCall("post", "token", escapedStr)
}

export const aUser = async (access_token?): Promise<ApiResponse<UserResponse>> => {
    if (isApiV3()) {
        // Use fetch for non-standard URL
        const fetchResult = await fetch(ssoURL + "/oauth2/userInfo", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${access_token}`
            }
        })
        const result = await fetchResult.json()
        return result
    } else {
        return slTokenApi.makeCall("get", `${prefix}/smrt-link/user`)
    }
}

export const setTokenHeader = (token?: AuthToken) => {
    slApis.forEach(api => {
        api.setHeader("Authorization", `Bearer ${token?.access_token}`)
    })
}

export const setVersion = (version?: string) => {
    // eslint-disable-next-line no-console
    //console.log(`API version is ${version}`)
    slApis.forEach(api => {
        api.setVersion(version)
    })
}

export const formatApiErrorResponse = (error: any): string => {
    let msg = "Server Error: "
    if (error.status) {
        msg += `${error.status} `
    }
    if (error.name) {
        msg += `${error.name}: `
    }
    if (error.data && error.data.errorType) {
        msg += `(${error.data.errorType}). `
    }
    if (error.message) {
        msg += `${error.message} `
    }
    if (error.data && error.data.message) {
        msg += error.data.message
    }
    return msg
}

export const isApiErrorResponse  = (err: any) => {
    return ("ok" in err) && ("problem" in err)
}

export { slApi }

/* ------- GENERAL ------- */

export const slStatus = (): Promise<ApiResponse<SmrtLinkStatus>> =>
    slApi.makeCall("get", `${prefix}/status`)

export const events = (options: any): Promise<ApiResponse<any>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/events`, options)

export const loggers = (options: LoggerOptions) =>
    slApi.makeCall("post", `${prefix}/smrt-link/loggers`, options)


/* ------- CONFIG ------- */

export type Config = { [key: string]: string };

export const getConfig = (): Promise<ApiResponse<Config[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/config`)

export const aConfig = (key: string): Promise<ApiResponse<Config>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/config/${key}`)

export const setConfig = (config: Config): Promise<ApiResponse<any>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/config`, config)

/* ------- DATASETS ------- */

const projectIdParam = (projectId:number) =>
    (projectId && projectId !== -1) ? "?projectId=" + projectId : ""

export const anySubreadByUuid = (uuid: string): Promise<ApiResponse<Dataset>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/datasets/subreads/${uuid}`)

export const anyDatasetRptsByUuid = (uuid: string, type: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/datasets/${type}/${uuid}/reports`)

export const anyDatasetById = (datasetId: number, projectId?: number ): Promise<ApiResponse<Dataset>> =>
    isApiV3()
        ? slApi.makeCall("get", `${prefix}/smrt-link/datasets/meta/${datasetId}${projectIdParam(projectId)}`)
        : slApi.makeCall("get", `${prefix}/smrt-link/datasets/${datasetId}${projectIdParam(projectId)}`)

export const putAnyDatasetById = (datasetId: number, putData: any ): Promise<ApiResponse<Dataset>> =>
    slApi.makeCall("put", `${prefix}/smrt-link/datasets/${datasetId}`, putData)

export const anyDatasetByUuid = (uuid: string, projectId?: number): Promise<ApiResponse<Dataset>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/datasets/${uuid}${projectIdParam(projectId)}`)

export const aDatasetByUuid =(
        { uuid, type, projectId, reportError = ERROR_MODE.default }:
        { uuid: string, type: string, projectId: number | null, reportError?: ERROR_MODE }
    ):
    Promise<ApiResponse<Dataset>> =>
        slApi.makeCall("get", `${prefix}/smrt-link/datasets/${type}/${uuid}${projectIdParam(projectId)}`, reportError)

export const aDatasetById = (
        { id, type, projectId }:
        { id: number, type: string, projectId?: number }
    ):
    Promise<ApiResponse<Dataset>> =>
        slApi.makeCall("get", `${prefix}/smrt-link/datasets/${type}/${id}${projectIdParam(projectId)}`)

export const nRootDatasets = (
        { setType, id, uuid, limit = 10000, projectId=-1,...query }:
        { setType: string, id: number, uuid: string, limit: number, projectId: number, query: any }
    ): Promise<ApiResponse<Dataset[]>> =>
        slApi.makeCall("get", `${prefix}/smrt-link/datasets/${setType}${projectIdParam(projectId)}`, {
            id,
            uuid,
            parentUuid: NULL_PARAM,
            limit,
            ...query
        })

export const nDatasets = (
        { setType, id, uuid, parentUuid, limit = 10000, projectId=-1,...query }:
        { setType: string, id?: number, uuid?: string, parentUuid?: string, limit?: number, projectId?: number, query?: any }
    ): Promise<ApiResponse<Dataset[]>> =>
        slApi.makeCall("get", `${prefix}/smrt-link/datasets/${setType}${projectIdParam(projectId)}`, {
            id,
            uuid,
            parentUuid,
            limit,
            ...query
        })

export const datasetSearch =
    (setType, query: any): Promise<ApiResponse<Dataset[]>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/datasets/${setType}/search`, query)

export const nRecordNamesByUuid = (setType: string, setUuid: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/datasets/${setType}/${setUuid}/record-names`)

export const nJobsByDatasetIdOrUuid = (id: number | string): Promise<ApiResponse<Job[]>> =>
    isApiV3()
        ? slApi.makeCall("get", `${prefix}/smrt-link/datasets/meta/${id}/jobs`)
        : slApi.makeCall("get", `${prefix}/smrt-link/datasets/${id}/jobs`)

export const aBarcodesContentsByDatasetIdOrUuid = (id: number | string): Promise<ApiResponse<string>> =>
    slBarcodeApi.makeCall("get", `${prefix}/smrt-link/datasets/barcodes/${id}/contents`)

export const nTemplateFiles = (sourceId?: string ): Promise<ApiResponse<TemplateFile[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/template-files${sourceId ? ("/" + sourceId) : ""}`)

/* --------- DATASTORE ----------- */

export const aReportFile = (jobId: number, datastoreUuid: string): Promise<ApiResponse<Report>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/analysis/${jobId}/reports/${datastoreUuid}`)

export const fileResourceUrl = (
        { jobId, datastoreUuid, fileName}:
        { jobId: number, datastoreUuid: string, fileName: string }): string => {
    return `${prefix}/smrt-link/job-manager/jobs/analysis/${jobId}/reports/${datastoreUuid}/resources?relpath=${fileName}`
}

export const aDatastoreFileDownloadUrl = (datastoreUuid: string): string => {
    return `${prefix}/smrt-link/datastore-files/${datastoreUuid}/download`
}

export const downloadDatastoreResource = async (file: UiDatastoreFile, filePath: string, savePrefix: string) => {
    if (isApiV3()) {
        awsDataStoreDownload(filePath, makeDownloadUrlSaveFileName(filePath, savePrefix))
    } else {
        const downloadUrl = datasetFileLink(file, savePrefix)
        const response = await slDownloadApi.makeCall("get", downloadUrl)
        const saveFileName = getOnPremFileNameFromHeaders(response.headers, filePath, savePrefix)
        saveApiResponseFile(response.data, saveFileName)
    }
}


const getOnPremFileNameFromHeaders = (headers, filePath: string, savePrefix: string): string => {
    const fNamePattern = /.*filename="([^"]+)"/
    const headerMatches = headers?.["content-disposition"]?.match(fNamePattern)
    if (headerMatches && headerMatches.length == 2) {
        return headerMatches[1]
    } else {
        // Fallback in case server config breaks content-disposition header transmit
        return makeDownloadUrlSaveFileName(filePath, savePrefix)
    }
}

const datasetFileLink = (file: UiDatastoreFile, prefix: string): string => {
    const prefixParam = (prefix && prefix.length  > 0) ? "?prefix=" + prefix : ""
    const downloadUrl = slServer + file.downloadUrl + prefixParam
    return downloadUrl
}

const saveApiResponseFile = (fileData, fileName: string) => {
    const tempUrl = URL.createObjectURL(fileData)
    const tempLink = document.createElement("a")
    tempLink.href = tempUrl
    tempLink.download = fileName
    tempLink.click()
}

const awsDataStoreDownload = async (cloudFilePath: string, saveFileName: string) => {
    const urlResponse = await slUploadApi.makeCall("post", `${prefix}/smrt-link/downloader-url`, { path: cloudFilePath })
    const { resourceUrl } = urlResponse.data
    // resourceUrl is a single use address which contains in the url itself a temporarily valid token for S3
    // using fetch as this followup request should not go through auth again
    const resp = await fetch(resourceUrl, {
        method: "GET",
    })
    const file = await resp.blob()
    saveApiResponseFile(file, saveFileName)
}

const makeDownloadUrlSaveFileName = (cloudFilePath: string, savePrefix: string): string => {
    const fileName = [cloudFilePath.slice(cloudFilePath.lastIndexOf("/") + 1)]
    if (savePrefix && savePrefix.length > 0) fileName.unshift(savePrefix)
    return fileName.join("-")
}

export const aDatastoreResourceUrl = (datastoreUuid: string, fileName: string) => {
    return `${prefix}/smrt-link/datastore-files/${datastoreUuid}/resources/?relpath=${fileName}`
}

export const aDatastoreFileDownload = (datastoreUuid: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", aDatastoreFileDownloadUrl(datastoreUuid))

export const nDatastoreFiles = (
        {jobId, jobType = DEFAULT_JOB_TYPE, options = {}} :
        {jobId: number, jobType?: string, options?: any}):
    Promise<ApiResponse<DatastoreFile[]>> =>
        slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobId}/datastore`, options)

export const nDatastoreViewRules = (): Promise<ApiResponse<PipelineDataStoreViewRule[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/pipeline-datastore-view-rules`)

/* ------- JOB --------- */

const DEFAULT_JOB_TYPE = "analysis"

export const aJob = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE ): Promise<ApiResponse<Job>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}`)

export const analysisJobs = (options: any = {}, projectId: number = -1): Promise<ApiResponse<Job[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/analysis-jobs${projectIdParam(projectId)}`, options)

export const autoAnalysisJobs = (parentJobId: number): Promise<ApiResponse<Job[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/analysis?parentJobId=${parentJobId}`)

export const postExportAnalysisJobs = (options: ExportAnalysisJobOptions): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/export-jobs`, options)

export const postJobsReportJob = (ids: number[]): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/jobs-report`, {ids: ids})

    export const nJobs = (
        { jobType, options = {}, projectId = -1 } :
        { jobType: string, options?: any, projectId?: number}):
    Promise<ApiResponse<Job[]>> =>
        slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}${projectIdParam(projectId)}`, options)

export const childJobs = (
        { parentMultiJobId, options = {}, projectId = -1} :
        { parentMultiJobId: number, options?: any, projectId?: number}):
    Promise<ApiResponse<Job[]>> => {
        options["parentMultiJobId"] = parentMultiJobId
        return nJobs({jobType: "analysis", options, projectId
    })
}

export const nJobsForFlatMode = (options: any = {}, projectId: number = -1): Promise<ApiResponse<Job[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/analysis/${projectIdParam(projectId)}`, options)


export const postAnalysisJob = (payload: JobOptions): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/${DEFAULT_JOB_TYPE}`, payload)

export const postJob = (jobType: string, payload: AnyJobOptions): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/${jobType}`, payload)

export const nJobEvents = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE ): Promise<ApiResponse<JobEvent[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}/events`)

export const nJobStatusEvents = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE ): Promise<ApiResponse<JobStatusEvent[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}/events`)

export const nJobEntryPoints = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE ): Promise<ApiResponse<JobEntryPoint[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}/entry-points`)

export const nJobOptions = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE ): Promise<ApiResponse<JobOptions>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}/options`)

export const nJobReports = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE ): Promise<ApiResponse<Report[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}/reports`)

export const nJobIgvFiles  = ( jobInt: number, jobType: string = DEFAULT_JOB_TYPE): Promise<ApiResponse<DatastoreFile[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/${jobType}/${jobInt}/datastore/igv-files`)

export const nMultiJobJobs = (jobType: string, jobInt: number): Promise<ApiResponse<Job[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${jobInt}/jobs`)

export const postMultiJob = (options: MultiJobOptions, jobType: string = "multi-analysis"): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}`, options)

export const addMultiJobJobs = (
        { multiJobId, jobType = "multi-analysis", options } :
        { multiJobId, jobType?: string, options }):
    Promise<ApiResponse<Job[]>> =>
        slApi.makeCall("post", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${multiJobId}/add-jobs`, options)

export const submitMultiJobJobs = (
        { multiJobId, jobType = "multi-analysis", options } :
        { multiJobId, jobType?: string, options }):
    Promise<ApiResponse<Job[]>> =>
        slApi.makeCall("post", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${multiJobId}/submit`, options)

export const getMultiJobJobs = (jobId: number, jobType: string = "multi-analysis"): Promise<ApiResponse<Job[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${jobId}/jobs`)

export const getMultiJob = (jobId: number, jobType: string = "multi-analysis"): Promise<ApiResponse<Job>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${jobId}`)

export const deleteMultiJob = (multiJobId, jobType: string = "multi-analysis") =>
    slApi.makeCall("delete", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${multiJobId}`)

export const deleteMultiJobJob = (
        { multiJobId, jobId, jobType = "multi-analysis" } :
        { multiJobId, jobId: number, jobType?: string}) =>
    slApi.makeCall("delete", `${prefix}/smrt-link/job-manager/multi-jobs/${jobType}/${multiJobId}/jobs/${jobId}`)

export const deleteJob = (options:DeleteJobOptions) =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/delete-job`, options)

export const terminateJob = (jobId: number) =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/${DEFAULT_JOB_TYPE}/${jobId}/terminate`, {})

export const aCopyJob = (options): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/copy-dataset`, options)

export const updateJob = (data: object, jobTypeId: string, id: number): Promise<ApiResponse<Job>> =>
    slJsonApi.makeCall("put", `${prefix}/smrt-link/job-manager/jobs/${jobTypeId}/${id}/metadata`, data)

export const aMergeJob = (options): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/merge-datasets`, options)

export const restartJob = (jobId: string): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/analysis/${jobId}/restart`)

export const exportDatasets = (options): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/export-datasets`, options)

export const makeDatasetReports = (options): Promise<ApiResponse<Job>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/jobs/make-dataset-reports`, options)

export const postTemplateFiles = (options: TemplateFilePostOptions): Promise<ApiResponse<TemplateFile>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/template-files`, options)
/* ------- RUN DESIGN --------- */

export const aRevioRun = (id: string | number): Promise<ApiResponse<RunSummary>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${id}`)

export const aRun = (id: string | number): Promise<ApiResponse<RunQcObject>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${id}`)

export const nRuns = (params: any = {}): Promise<ApiResponse<RunQcObject[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs`, params)

export const nRunsAsDesigns= (projectId: number): Promise<ApiResponse<DesignModel[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs${projectIdParam(projectId)}`)

export const nRunsBarcodes = (runId: string, collectionId: string): Promise<ApiResponse<ServerBarcodeResponse[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${runId}/collections/${collectionId}/barcodes`)

export const nRunsMultiJobId = (multiJobId): Promise<ApiResponse<DesignModel[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs?multiJobId=${multiJobId}`)

export const nRunsCollectionUuid = (collectionId): Promise<ApiResponse<DesignModel[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs?collectionUuid=${collectionId}`)

export const nRunsMovieName = (movieName: string): Promise<ApiResponse<DesignModel[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs?movieName=${movieName}`)

export const nRunsSequencingKit = (barcode, labelNumber): Promise<ApiResponse<RunSummary[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs?sequencingKit=${barcode}&labelNumber=${labelNumber}`)

export const addRun = (runXML: any): Promise<ApiResponse<DesignObject>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/runs`, runXML)

export const updateRun = (id: string | number, runXML: any): Promise<ApiResponse<DesignObject>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/runs/${id}`, runXML)

export const importRunCSV = (runCSVPath: any): Promise<ApiResponse<RunDesign>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/import-run-design`, {"path": runCSVPath})

export const addRunDesign = (runDesign: RunDesign): Promise<ApiResponse<RunSummary>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/run-design`, runDesign)

export const updateRunDesign = (runDesign: RunDesign): Promise<ApiResponse<RunSummary>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/run-design/${runDesign.uuid}`, runDesign)

export const getRunDesign = (id: string): Promise<ApiResponse<RunDesign>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/run-design/${id}`)

export const deleteRunDesign = (id: string | number): Promise<ApiResponse<void>> =>
    slApi.makeCall("delete", `${prefix}/smrt-link/runs/${id}`)

export const saveCollectionBarcodes = (
        { runId, collectionId, barcodes } :
        { runId: string, collectionId: string, barcodes: ServerBarcode[] }):
    Promise<ApiResponse<void>> =>
        slApi.makeCall("post", `${prefix}/smrt-link/runs/${runId}/collections/${collectionId}/barcodes`, barcodes)

export const nRunCollections  = (runId): Promise<ApiResponse<Collection[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${runId}/collections`)

export const getCollectionInfo  = (runId, collectionId): Promise<ApiResponse<Collection>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${runId}/collections/${collectionId}`)

export const deleteCollectionBarcodes = (runId, collectionId): Promise<ApiResponse<void>> =>
    slApi.makeCall("delete", `${prefix}/smrt-link/runs/${runId}/collections/${collectionId}/barcodes`)

export const runDesignDefaults = (): Promise<ApiResponse<ApplicationsResponse>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/bundles/chemistry-pb/active/files/RunDesignDefaults.json`)

export const nRunReports = (runId): Promise<ApiResponse<DatastoreReportFile[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${runId}/reports`)

export const runQcReports = (runId: string | number): Promise<ApiResponse<RunQcSampleObject[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/runs/${runId}/qc`)

export const mockPreviewReport = (): Promise<ApiResponse<Report>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/job-manager/jobs/analysis/158/reports/6ad9e5df-5aaf-44d3-9499-5ad7a0cc163d`)


export const stopRun = (instrument: InstrumentConnection, runId: string):Promise<ApiResponse<any>> => {
    const options = {id: runId}
    return postInstrumentEndpoint(instrument, "/runcontrol/stoprun", options)
}

/* ------- Sample --------- */
export const aSample = (id: string | number): Promise<ApiResponse<SampleObject>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/samples/${id}`)

export const nSample = (params: any = {}): Promise<ApiResponse<SampleObject[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/samples`, params)

export const addSample = (data: object): Promise<ApiResponse<SampleObject>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/samples`, data)

export const updateSample = (id: string | number, data: object): Promise<ApiResponse<SampleObject>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/samples/${id}`, data)

export const deleteSample = (id: string | number): Promise<ApiResponse<void>> =>
    slApi.makeCall("delete", `${prefix}/smrt-link/samples/${id}`)


/* ------- User --------- */

export const getUserList = (params: any = {}): Promise<ApiResponse<User[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/users`, params)


/* ------- Pipelines --------- */

export const nPipelineTemplates = (): Promise<ApiResponse<PipelineTemplate[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/resolved-pipeline-templates`)

export const aPipelineTemplates = (pt_dotdotid: string): Promise<ApiResponse<PipelineTemplate>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/resolved-pipeline-templates/${pt_dotdotid}`)

export const nPresets = (pt_dotdotid: string): Promise<ApiResponse<PipelineTemplate>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/resolved-pipeline-templates/${pt_dotdotid}/presets`)

export const nPipelineViewRules = (): Promise<ApiResponse<PipelineViewRule[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/pipeline-template-view-rules`)

export const aPipelineViewRule = (pt_dotdotid: string): Promise<ApiResponse<PipelineViewRule>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/pipeline-template-view-rules/${pt_dotdotid}`)


/* ------- Workflow Presets --------- */

export const nWorkflowPresets = (): Promise<ApiResponse<WorkflowPreset[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/workflow-presets`)

export const aWorkflowPreset = (preset_id: string): Promise<ApiResponse<WorkflowPreset>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/workflow-presets/${preset_id}`)

/* ------- Report view rules --------- */

export const nReportViewRules = (): Promise<ApiResponse<ReportViewRule[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/report-view-rules`)

export const aReportViewRule = (reportType: string): Promise<ApiResponse<ReportViewRule>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/report-view-rules/${reportType}`)

/* ------- Automation Constraints (includes part numbers) --------- */

export const automationConstraints = (): Promise<ApiResponse<string>> =>
    slXmlApi.makeCall("get",`${prefix}/smrt-link/bundles/chemistry-pb/active/files/definitions%2FPacBioAutomationConstraints.xml`)

export const sampleSetupApplicationParams = (): Promise<ApiResponse<SampleSetupApplications>> =>
    slApi.makeCall("get",`${prefix}/smrt-link/bundles/chemistry-pb/active/files/SampleSetupParams.json`)

export const appConfigOverrides = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/bundles/chemistry-pb/active/files/smrtlink-app-config.json`)

/* ---------- Bundle Upgrade ----------- */

export const chemistryBundleVersion = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/bundles/chemistry-pb/active`)

export const uiBundleVersion = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/bundles/smrtlink-ui/active`)

export const checkBundleUpgrade = (bundleTypeId: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/bundles/${bundleTypeId}/upgrade`)

export const doBundleUpgrade = (bundleTypeId: string, bundleVersion: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/bundles/${bundleTypeId}/${bundleVersion}/upgrade`)

/* ---------- SMRT Link Upgrade ----------- */

export const checkSMRTLinkUpgrade = (bundleVersion: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/bundles/smrtlink-update/${bundleVersion}/download-update`)

export const doSMRTLinkUpgrade = (bundleVersion: string): Promise<ApiResponse<any>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/bundles/smrtlink-update/${bundleVersion}/download-update`)

export const restartServices = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/job-manager/admin-jobs/restart-services`, {})

/* ---------- Version Manifest --------- */

export const versionManifest = (): Promise<ApiResponse<ComponentVersionInfo[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/manifests`)

/* ---------- Eula --------- */

export const getEula = (smrtlinkVersion: string): Promise<ApiResponse<Eula>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/eula/${smrtlinkVersion}`)

export const addEula = (eula: Eula): Promise<ApiResponse<Eula>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/eula`, eula)

/* ---------- Disk Space --------- */

export const diskSpaceRoot = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/disk-space/smrtlink.resources.root`)

export const diskSpaceJobs = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/disk-space/smrtlink.resources.jobs_root`)

export const diskSpaceTmp = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/disk-space/smrtlink.resources.tmpdir`)

/* ---------- Image --------- */
// For use with run qc api results, url has leading /
export const getImageWithPrefix = (urlWithoutPrefix: string): Promise<any> =>
    getImage(`${prefix}${urlWithoutPrefix}`)

export const getImage = (url: string): Promise<any> => {
    return slImageApi.makeCall("get", url).then(
        response => {
            return response.data
        },
        () => { return "" }
    )
}

/* ---------- LS Service ----------- */

export const getFolder = (path: string): Promise<ApiResponse<FolderModel>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/files${path}`)

export const upload = (file: File): Promise<string> => {
   if (isApiV3()) {
    return awsUpload(file)
   } else {
    return onPremUpload(file)
   }
}

const onPremUpload = (file: File): Promise<string> => {
    const formData = new FormData()
    formData.append("upload_file", file)
    return slUploadApi.makeCall("post", `${prefix}/smrt-link/uploader`, formData).then(
        response => {
            if (response.ok) {
                return response.data.path
            }
        }
    )
}

const awsUpload = async (file: File): Promise<string> => {
    const urlResponse = await slUploadApi.makeCall("post", `${prefix}/smrt-link/uploader-url`, { fileName: file.name })
    const { uploadUrl, resourceUrl } = urlResponse.data
    // uploadUrl is a single use address which contains in the url itself a temporarily valid token for S3
    // using fetch as this followup request should not go through auth again
    await fetch(uploadUrl, {
        method: "PUT",
        body: file,
    })

    return resourceUrl
}

export const uploadErrorMessage = (error: ApiErrorResponse<any>) => {
    if (error.status === 413) {
        const maxUploadMb = appConfig.maxUploadableFileSize / (1000 * 1000)
        return `File is too large.  Maximum file size is ${maxUploadMb} Mb.`
    } else if (error.status === 408) {
        const maxUploadMb = appConfig.maxUploadableFileSize / (1000 * 1000)
        return "The file could not be uploaded because it took too long to transfer it to the server. " +
            "Try compressing the file using gzip, using a smaller file, or improving the network speed. " +
            `Maximum file size is ${maxUploadMb} Mb.`
    } else {
        return formatApiErrorResponse(error)
    }
}
/* ------------- PROJECT ------------- */

export const getProjects = (): Promise<ApiResponse<Project[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/projects`)

export const addProject = (project: Project): Promise<ApiResponse<Project>> =>
    slJsonApi.makeCall("post", `${prefix}/smrt-link/projects`, project)

export const getProject = (id: number): Promise<ApiResponse<Project>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/projects/${id}`)

export const updateProject = (project: Project): Promise<ApiResponse<Project>> =>
    slJsonApi.makeCall("put", `${prefix}/smrt-link/projects/${project.id}`, project)

export const deleteProject = (id: number): Promise<ApiResponse<Project>> =>
    slApi.makeCall("delete", `${prefix}/smrt-link/projects/${id}`)

/* ------------- ALARMS ------------- */

export const getAlarms = (): Promise<ApiResponse<AlarmModel[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/alarms`)

/* ------------- NOTIFICATIONS ------------- */

export const getNotifications = (nDays): Promise<ApiResponse<NotificationModel[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/notifications`, {newerThan: nDays})

export const updateNotifications = (ids: number[], unread: boolean): Promise<ApiResponse<string>> =>
    slApi.makeCall("put", `${prefix}/smrt-link/notifications`, {
        notificationIds: ids,
        unread: unread
    })

export const getNotificationsLog = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/logs/notifications.log`)

export const getAuditLog = (): Promise<ApiResponse<any>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/logs/audit.log`)

/* ------------- USER PREFS ---------- */
export const getUserPreferences = (user: User): Promise<ApiResponse<UserPreferences>> => {
    if (!user) {
        return Promise.reject("getUserPreferences was called with a !user")
    }
    return slApi.makeCall("get", `${prefix}/smrt-link/user/preferences`).then( response => {
        if (response.ok) {
            const prefs = jsonToUserPreferences(response.data)
            return {...response, data: prefs}
        }
        else {
            return response
        }
    })
}

export const updateUserPreferences = (prefs: UserPreferences): Promise<ApiResponse<UserPreferences>> => {
    const payload = userPreferencesToJson(prefs)
    return slApi.makeCall("post", `${prefix}/smrt-link/user/preferences`, payload)
}

/* ------------- KEYCLOAK ------------- */

export const getEnabledUsers = (maxItemLimit?: number, start?: number): Promise<ApiResponse<KeycloakUser[]>> => {
  const max = (maxItemLimit === undefined) ? -1 : maxItemLimit
  const first = (start === undefined) ? 0 : start
  return slKeycloakApi.makeCall("get", `${prefix2}/usermgmt/enabled-users?first=${first}&max=${max}`)
}

export const getAllUsers = (filter?: string, maxItemLimit?: number, start?: number): Promise<ApiResponse<KeycloakUser[]>> => {
  const max = (maxItemLimit === undefined) ? -1 : maxItemLimit
  const first = (start === undefined) ? 0 : start
  return slKeycloakApi.makeCall("get", `${prefix2}/usermgmt/users?first=${first}&max=${max}&search=${filter}`)
}

export const getUserListByName = (username: string): Promise<ApiResponse<KeycloakUser[]>> => {
  return slKeycloakApi.makeCall("get", `${prefix2}/usermgmt/users?username=${username}&exact=true`);
}

export const getUser = (userId: string): Promise<ApiResponse<KeycloakUser>> =>
  slKeycloakApi.makeCall("get", `${prefix2}/usermgmt/users/${userId}`)

export const getUserListOfRole = (role: string, maxItemLimit?: number, start?: number): Promise<ApiResponse<KeycloakUser[]>> => {
  const max = (maxItemLimit === undefined) ? -1 : maxItemLimit
  const first = (start === undefined) ? 0 : start
  return slKeycloakApi.makeCall("get", `${prefix2}/usermgmt/roles/${role}/users?first=${first}&max=${max}`)
}

export const getRolesForUser = (userId: string): Promise<ApiResponse<KeycloakUserRole[]>> =>
  slKeycloakApi.makeCall("get", `${prefix2}/usermgmt/users/${userId}/roles`)

// this returns HTTP 204 No Content
export const addUserRoles = (userId: string, roles: KeycloakUserRole[]): Promise<ApiResponse<void>> =>
  slKeycloakApi.makeCall("post", `${prefix2}/usermgmt/users/${userId}/roles`, roles)

// this returns HTTP 204 No Content
// note: the keycloak API actually takes DELETE, KrakenD will transform the
// method while propagating the request body
export const deleteUserRoles = (userId: string, roles: KeycloakUserRole[]): Promise<ApiResponse<void>> =>
  slKeycloakApi.makeCall("post", `${prefix2}/usermgmt/users/${userId}/delete-roles`, roles)

export const inviteUser = (firstName: string, lastName: string,
    email: string, role: string): Promise<ApiResponse<{message: string}>> => 
    slApi.makeCall("post", `${prefix}/usermgmt/invite-user`, {firstName, lastName, email, role})

/* -------------Instruments -------- */

export const getInstruments = (): Promise<ApiResponse<Instrument[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/instruments`)

export const getInstrument = (serial: string): Promise<ApiResponse<Instrument>> => {
    return slApi.makeCall("get", `${prefix}/smrt-link/instruments/${serial}`)
}

export const getConnectedInstruments = (): Promise<ApiResponse<InstrumentConnection[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/instrument-config/connections`)

export const getInstrumentConfigSummary = (): Promise<ApiResponse<InstrumentSummary[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/instrument-config/summary`)

export const updateInstrumentConnection =
    (id: number, options: InstrumentConnectionPartial ): Promise<ApiResponse<InstrumentConnection>> =>
        slApi.makeCall("post", `${prefix}/smrt-link/instrument-config/connections/${id}`, options)

export const createInstrumentConnection =
    (options: InstrumentConnectionCreate ): Promise<ApiResponse<InstrumentConnection>> =>
        slApi.makeCall("post", `${prefix}/smrt-link/instrument-config/connections`, options)

export const reconnectInstrument = (id: number): Promise<ApiResponse<InstrumentConnection>> => {
    const options = {isConnected: true}
    return slApi.makeCall("put", `${prefix}/smrt-link/instrument-config/connections/${id}`, options)
}

export const getFileTransferLocations = (): Promise<ApiResponse<FileTransferLocation[]>> =>
    slApi.makeCall("get", `${prefix}/smrt-link/instrument-config/file-transfer`)

export const deleteFileTransferLocations = (locationId:string): Promise<ApiResponse<any>> =>
    slApi.makeCall("delete", `${prefix}/smrt-link/instrument-config/file-transfer/${locationId}`)

export const updateFileTransferLocation = (ftl: FileTransferLocationPartial): Promise<ApiResponse<any>> => {
    if (!ftl.uuid) {
       return Promise.reject("Error: api.updateFileTransferLocation expected a fileTransferLocation with a valid uuid")
    }
    return slApi.makeCall("put", `${prefix}/smrt-link/instrument-config/file-transfer/${ftl.uuid}`, ftl)
}

export const postNewFileTransferLocation = (ftl: FileTransferLocationPartial): Promise<ApiResponse<any>> =>
    slApi.makeCall("post", `${prefix}/smrt-link/instrument-config/file-transfer`, ftl)

export const getInstrumentConfiguration = (instrument: InstrumentRecord): Promise<ApiResponse<any>> =>
    getInstrumentEndpoint(instrument, "/configuration")

export const postTransferSchemeTest = (
      instrument: InstrumentRecord,
      ftl: ICSFileTransferLocation): Promise<ApiResponse<TransferSchemeTest>> =>
    postInstrumentEndpoint(instrument, "/configuration/transferscheme/test", ftl)

export const getTransferSchemeTest = (instrument: InstrumentRecord): Promise<ApiResponse<TransferSchemeTest>> =>
    getInstrumentEndpoint(instrument, "/configuration/transferscheme/test")

export const getFailedTransfers = (instrument: InstrumentRecord): Promise<ApiResponse<FailedTransfersData>> => {
    return getInstrumentEndpoint(instrument, "/failedtransfers/")
}

export const getFailedTransfer = (serial: string, collectionId: string): Promise<ApiResponse<FailedTransfer>> => {
    // TODO(pfernhout) Better API not supported widely yet, so uses more general instruments API to locate the info we need
    // return getInstrumentEndpoint(instrument, `/failedtransfers/${collectionId}`)
    return getInstruments().then( response => {
        if (!response.ok) { return response as any }
        const instrumentData = response.data?.find(instrument => instrument.serial === serial)
        const failedTransfer = instrumentData
            ? instrumentData.state?.failedTransfers?.find(item => item.acqId === collectionId)
            : null
        return {
            ok: response.ok,
            data: failedTransfer,
            problem: response.problem as any,
            originalError: response.originalError as any
        }
    })
}

export const retryFailedTransfer = (instrument: InstrumentRecord, collectionId: string): Promise<ApiResponse<void>> => {
    return postInstrumentEndpoint(instrument, `/failedtransfers/${collectionId}/retry`, {})
}

export const getInstrumentRuns = (instrument: InstrumentRecord, runId: string): Promise<ApiResponse<any>> =>
    getInstrumentEndpoint(instrument, `/runs/${runId}`)

// Workspace
export const getTenant = (): Promise<ApiResponse<Tenant>> =>
    slApi.makeCall("get", `${prefix}/workspace`)

// for SL pass through to ICS api
const getInstrumentEndpoint = (instrument: InstrumentRecord, endpoint: string): Promise<ApiResponse<any>> => {
    const instId = (isApiV3()) ? instrument.serial : instrument.id.toString()
    return slApi.makeCall("get", `${instrumentPrefix}/${instId}${endpoint}`)
}

const postInstrumentEndpoint = (instrument: InstrumentRecord, endpoint: string, options:any): Promise<ApiResponse<any>> => {
    const instId = (isApiV3()) ? instrument.serial : instrument.id.toString()
    return slApi.makeCall("post", `${instrumentPrefix}/${instId}${endpoint}`, options)
}

/* --------------------- */
