/* eslint-disable max-len */

import React, { FunctionComponent, Suspense, useEffect } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import ReduxToastr from "react-redux-toastr"

import { AcceptCertPage } from "../core-components/accept-cert/AcceptCertPage"
import { NotFoundPage } from "../core-components/containers/NotFoundPage"
import { HelpPage } from "../core-components/containers/HelpPage"
import { BadPermPage } from "../core-components/containers/BadPermPage"
import { ErrorPage } from "../core-components/containers/ErrorPage"
import { buildQueryStr } from "./utils/url-query-helper"
import { useDispatch } from "react-redux"
import { getAlarms } from "../data/stores/alarm/alarm-api-actions"
import { useIsSmrtlinkLite } from "./config/use-is-smrtlink-lite"
import { Loading } from "../core-components/Loading"
import { NavBar } from "../core-components/containers/NavBar"
import { isApiV3 } from "../data/api"
import { AuthenticatedPage } from "../core-components/login/containers/AuthenticatedPage"
import { SiteWideBanner } from "../core-components/shared/SiteWideBanner"
import { fetchTenant } from "src/data/stores/tenant/tenant-actions"
import { useIsFreeMode } from "src/core/store/core-selectors"

const ConfigPage = React.lazy( () =>
    import("../silos/configuration/containers/ConfigPage").then(mod => ({ default: mod.ConfigPage })))
const DataManagementPage = React.lazy( () => import( "../silos/data-management/containers/DataManagementPage")
    .then(mod => ({ default: mod.DataManagementPage })))
const CreateDatasetPage = React.lazy( () => import("../silos/data-management/containers/CreateDatasetPage")
    .then(mod => ({ default: mod.CreateDatasetPage })))
const CreateProjectPage = React.lazy( () => import("../silos/data-management/containers/CreateProjectPage")
    .then(mod => ({ default: mod.CreateProjectPage })))
const DataManagementImportPage = React.lazy( () => import( "../silos/data-management/containers/DataManagementImportPage")
    .then(mod => ({ default: mod.DataManagementImportPage })))
const ExportDatasetPage = React.lazy( () => import("../silos/data-management/containers/ExportDatasetPage")
    .then(mod => ({ default: mod.ExportDatasetPage })))
const DatasetDetailsPage = React.lazy( () => import( "../silos/data-management/components/dataset-details/DatasetDetailsPage")
    .then(mod => ({ default: mod.DatasetDetailsPage })))
const DevPage = React.lazy( () => import( "../core-components/dev/DevPage")
    .then(mod => ({ default: mod.DevPage })))
const LoginPage = React.lazy( () => import("../core-components/login/containers/LoginPage")
    .then(mod => ({ default: mod.LoginPage })))
const RunsPage = React.lazy( () => import("../silos/runs/containers/RunsPage")
    .then(mod => ({ default: mod.RunsPage })))
const RunDesignEditorPage = React.lazy( () => import("../silos/runs/containers/RunDesignEditorPage")
    .then(mod => ({ default: mod.RunDesignEditorPage })))
const RunQCDetailPage = React.lazy( () => import("../silos/runs/containers/RunQCDetailPage")
    .then(mod => ({ default: mod.RunQCDetailPage })))
const SampleSetupListPage = React.lazy( () => import("../silos/sample-setup/containers/SampleSetupListPage")
    .then(mod => ({ default: mod.SampleSetupListPage })))
const SampleSetupEditorPage = React.lazy( () => import("../silos/sample-setup/containers/SampleSetupEditorPage")
    .then(mod => ({ default: mod.SampleSetupEditorPage })))
const ProtectedRoute = React.lazy( () => import("../core-components/ProtectedRoute")
    .then(mod => ({ default: mod.ProtectedRoute })))
const AnalysisListPage = React.lazy( () => import("../silos/analysis/containers/AnalysisListPage")
    .then(mod => ({ default: mod.AnalysisListPage })))
const AnalysisResultsPage = React.lazy( () => import("../silos/analysis/containers/AnalysisResultsPage")
    .then(mod => ({ default: mod.AnalysisResultsPage })))
const NewAnalysisPage = React.lazy( () => import("../silos/analysis/containers/NewAnalysis")
    .then(mod => ({ default: mod.NewAnalysisPage })))
const NewAnalysisStepTwoPage = React.lazy( () => import("../silos/analysis/containers/NewAnalysisStepTwoPage")
    .then(mod => ({ default: mod.NewAnalysisStepTwoPage })))
const AnalysisSummaryPage = React.lazy( () => import("../silos/analysis/containers/AnalysisSummaryPage")
    .then(mod => ({ default: mod.AnalysisSummaryPage })))
const TestPartsChooser = React.lazy( () => import("../core-components/dev/TestPartsChooser")
    .then(mod => ({ default: mod.TestPartsChooser })))
const RunSummaryPage = React.lazy( () => import("../silos/runs/containers/RunSummaryPage")
    .then(mod => ({ default: mod.RunSummaryPage })))
const AnalysisImportPage = React.lazy( () => import( "../silos/analysis/containers/AnalysisImportPage")
    .then(mod => ({ default: mod.AnalysisImportPage })))
const AnalysisExportPage = React.lazy( () => import("../silos/analysis/containers/AnalysisExportPage")
    .then(mod => ({ default: mod.AnalysisExportPage })))
const ErrorBoundary = React.lazy( () => import("../core-components/shared/Error/ErrorBoundary")
    .then(mod => ({ default: mod.ErrorBoundary })))
const TestWSO2Page = React.lazy( () => import("../core-components/dev/TestWSO2Page")
    .then(mod => ({ default: mod.TestWSO2Page })))
const DevConfigPage = React.lazy( () => import("../silos/configuration/containers/DevConfigPage")
    .then(mod => ({ default: mod.DevConfigPage })))
const InstrumentsHome = React.lazy( () => import("../silos/instruments/instruments-home/InstrumentsHome")
    .then(mod => ({ default: mod.InstrumentsHome })))

export const DOC_ROOT = (isApiV3() || process.env.REACT_APP_IS_DEV_MODE === "true") ? "" : "/sl"

const ALARM_POLLING_DELAY_MS = 10 * 60 * 1000

const getHashPathRedirect = () => {
    // This redirects hash paths from the previous version of SMRT Link to
    // equivalent new BrowserRouter URLs
    const remappedURL = window.location.hash.substring(1).replace(";type=", "?type=")
    return <Redirect to={remappedURL} />
}

const hashPathRedirect = () => {
    if (window.location.hash && window.location.hash.startsWith("#/")) {
        return getHashPathRedirect()
    }
    return <Redirect to="/home" />
}


export const Routes: FunctionComponent = () => {

    const dispatch = useDispatch()

    const isSmrtLinkLite = useIsSmrtlinkLite()
    const isFreeMode = useIsFreeMode()

    const hideAnalysisFeatures = isSmrtLinkLite || isFreeMode
    const hideDMFeatures = isFreeMode

    useEffect(() => {
        dispatch(fetchTenant())
        dispatch(getAlarms())
        setInterval(() => {
            dispatch(getAlarms())
        },          ALARM_POLLING_DELAY_MS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
    <div className={"App" + (isApiV3() ? " pacb-cloud" : "")}>
                <header role="banner">
                    <Route path="*" component={NavBar} />
                </header>
                <main className="main" role="main">
                    <SiteWideBanner/>
                    <Suspense fallback= { <Loading/>}> <ErrorBoundary>
                        <Switch>
                            <Route exact path="/sl" render={hashPathRedirect} />
                            <Route exact path="/" render={hashPathRedirect} />

                            <Redirect from="/about" to="/settings/about" />
                            <Redirect from="/config/instrument" to="/settings/instruments" />
                            <Redirect from="/config/users" to="/settings/users" />
                            <Redirect from="/config/formatting" to="/settings/general" />
                            <Redirect from="/config/kinetics" to="/settings/general" />
                            <Redirect from="/config" to="/settings/general" />
                            <Redirect from="/home" to="/instruments" />
                            <Redirect from="/welcome" to="/instruments" />

                            <Route path="/accept-cert" component={AcceptCertPage} />

                            <ProtectedRoute exact path="/analysis" 
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisListPage} guard="bioinformatician" />
                            {/* backwards compatibility for SL 7.x and earlier */}
                            <ProtectedRoute exact path="/analysis/jobs"
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisListPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/analysis/import"
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisImportPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/analysis/export"
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisExportPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/analysis/summary"
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisSummaryPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/analysis/results/:id"
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisResultsPage} guard="bioinformatician" />
                            {/* backwards compatibility for SL 7.x and earlier */}
                            <ProtectedRoute exact path="/analysis/job/:id"
                                component={hideAnalysisFeatures ? NotFoundPage : AnalysisResultsPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/analysis/create-analysis-step-1"
                                component={hideAnalysisFeatures ? NotFoundPage : NewAnalysisPage} guard="bioinformatician" />
                            {/* Special route for data-management jobs in SL-Lite"  */}
                            <ProtectedRoute exact path="/data-management/analysis-results/:id" 
                                component={AnalysisResultsPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/analysis/create-analysis-step-2"
                                component={hideAnalysisFeatures ? NotFoundPage : NewAnalysisStepTwoPage} guard="bioinformatician" />

                            <ProtectedRoute exact path="/data-management" 
                                component={hideDMFeatures ? NotFoundPage : DataManagementPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/data-management/project-detail" 
                                component={hideDMFeatures ? NotFoundPage : CreateProjectPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/data-management/project-detail/:id" 
                                component={hideDMFeatures ? NotFoundPage : CreateProjectPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/data-management/create-dataset" 
                                component={hideDMFeatures ? NotFoundPage : CreateDatasetPage} guard="bioinformatician" />
                            {/* DM details page needs to be visitable in free mode for run qc links */}
                            <ProtectedRoute exact path="/data-management/dataset-detail/:uuid" 
                                component={DatasetDetailsPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/data-management/import" 
                                component={hideDMFeatures ? NotFoundPage : DataManagementImportPage} guard="bioinformatician" />
                            <ProtectedRoute exact path="/data-management/export" 
                                component={hideDMFeatures ? NotFoundPage :  ExportDatasetPage} guard="bioinformatician" />

                            <ProtectedRoute exact path="/dev" component={DevConfigPage} guard="user" />
                            <ProtectedRoute exact path="/dev/test-parts-chooser" component={TestPartsChooser} guard="user" />
                            <ProtectedRoute exact path="/dev/wso2" component={TestWSO2Page} guard="user" />
                            <ProtectedRoute exact path="/dev/misc" component={DevPage} guard="user" />

                            {/* eventually goes to the RunsHome component */}
                            <ProtectedRoute exact path="/runs" component={RunsPage} guard="bioinformatician" />
                            {/* eventually goes to the RunDetails component */}
                            <ProtectedRoute exact path="/runs/:id" component={RunQCDetailPage} guard="bioinformatician" />
                            {/* backwards compability for pre-revio */}
                            <Route exact path="/run-qc/:id" render={
                                props => ( <Redirect to={`/runs/${props.match.params.id}`} /> )}
                            />
                            {/* these get renamed */}
                            <ProtectedRoute exact path="/runs/design/:id/summary" component={RunSummaryPage} guard="labtech" />
                            <ProtectedRoute exact path="/runs/design/:id/copy" component={RunDesignEditorPage} guard="labtech" />
                            <ProtectedRoute exact path="/runs/design/:id" component={RunDesignEditorPage} guard="labtech" />
                            <ProtectedRoute exact path="/runs/design/new" component={RunDesignEditorPage} guard="labtech" />

                            <ProtectedRoute exact path="/instruments" component={InstrumentsHome} guard="bioinformatician" />
                            <ProtectedRoute exact path="/sample-setup" component={SampleSetupListPage} guard="labtech" />
                            <ProtectedRoute path="/sample-setup/edit" component={SampleSetupEditorPage} guard="labtech" />
                            <ProtectedRoute path="/settings" component={ConfigPage} guard="user" />
                            <Route path="/login" component={LoginPage}/>
                            <Route path="/authenticated" component={AuthenticatedPage}/>
                            <Route path="/help" component={HelpPage} />
                            <Route path="/bad-perm" component={BadPermPage} />
                            <Route path="/error" component={ErrorPage} />
                            <Route path="*" component={NotFoundPage}/>
                        </Switch>
                    </ErrorBoundary></Suspense>
                </main>
                <ReduxToastr
                    timeOut={4000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                />
    </div>
    )
}

export const DATATYPE_PARAM = "type"

/*
-  Use isAbsolute=false for urls in <Link> elements
-  Use isAbsolute=false for urls in history.push calls
-  Use isAbsolute=true (default) for urls in <a> tags
*/
export const createLink = (url: string, isAbsolute: boolean) => {
    return isAbsolute ? `${DOC_ROOT}${url}` : url
}

export const datasetDetailsLink = (uuid:  string, dataType?: string, isAbsolute?: boolean): string => {
    return dataType ?
        createLink(`/data-management/dataset-detail/${uuid}?${DATATYPE_PARAM}=${dataType}`, isAbsolute) :
        createLink(`/data-management/dataset-detail/${uuid}`, isAbsolute)
}

export const analysisResultsLink = (jobId: number | string, isAbsolute?: boolean): string => {
    return createLink(`/analysis/results/${jobId}`, isAbsolute)
}

export const dmAnalysisResultsLink = (jobId: number | string, isAbsolute?: boolean): string => {
    return createLink(`/data-management/analysis-results/${jobId}`, isAbsolute)
}

export const createAnalysisLink = (query: any, isAbsolute?: boolean) => {
    return createLink(
        `/analysis/create-analysis-step-1${buildQueryStr(query)}`,
        isAbsolute
    )
}

export const projectDetailsLink = (id: number, isAbsolute?: boolean) => {
    return createLink(`/data-management/project-detail/${id}`, isAbsolute)
}

export const runDesignLink = ( uniqueId: string, isAbsolute?: boolean) => {
    return createLink(`/runs/design/${uniqueId}`, isAbsolute)
}

export const runSummaryLink = ( uniqueId: string, isAbsolute?: boolean) => {
    return createLink(`/runs/design/${uniqueId}/summary`, isAbsolute)
}

export const runLink = ( runId: string, runStatus: string, isAbsolute?: boolean) => {
    if (runStatus === "Ready") {
        return createLink(`/runs/design/${runId}/summary`, isAbsolute)
    } else {
        return createLink(`/runs/${runId}`, isAbsolute)
    }
}

export const runQcDetailsLink = ( id:string, isAbsolute?: boolean) => {
    return createLink(`/runs/${id}`, isAbsolute)
}

export const sampleSetupEditLink = ( uniqueId: string)=> {
    return createLink(`/sample-setup/edit?id=${uniqueId}`, true)
}

export const sampleSetupEditLCLink = ( uniqueId: string)=> {
    return createLink(`/sample-setup/edit?group=${uniqueId}`, true)
}

export const getNotificationLogsLink = (isAbsolute?: boolean)=> {
    // TODO (SL-8062) Update to correct link
    return createLink("/sample-setup/edit", isAbsolute)
}

