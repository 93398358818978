import React, { FunctionComponent, Fragment, useState, useMemo } from "react"

import { useDispatch, useSelector } from "react-redux"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Row, Col } from "reactstrap"
import { ApplicationState } from "../../../core/types"
import { updateNotifications } from "../../../data/stores/notifications/notifications-actions"
import { NotificationsTable } from "./NotificationsTable"
import { MarkAsButton } from "./MarkAsButton"
import * as API from "../../../data/api"
import { nowForFileNames } from "../../../core/utils/smrt-link-date-format"
import { createDownload } from "../../../core/utils/create-download"
import { displayInlineError } from "../../shared/Error/ErrorBoundary"
import { useNotifications } from "../../../data/stores/notifications/use-notifications"
import { useUserPreferences } from "../../../data/stores/user-preferences/use-user-preferences"
import { NotificationRow, groupNotifications } from "./notifications-table-helper"
import { includeNotification } from "src/data/model/user-preferences-model"
import { Link } from "react-router-dom"

interface Props {
    className?: string
    labelledBy?: string
}

export const ModalShowNotifications: FunctionComponent<Props> = props => {

    const dispatch = useDispatch()

    const notifications = useNotifications()

    const userPrefs = useUserPreferences()
    const notificationPrefs = userPrefs ? userPrefs.notificationPreferences : null

    const rows: NotificationRow[] = useMemo( () => {
        const displayedNotifications = notificationPrefs ? 
            notifications.filter( notification => includeNotification(notification, notificationPrefs)) :
            notifications
        return groupNotifications(displayedNotifications)
    },[notifications])


    const loading =  useSelector<ApplicationState,boolean>( state => state.notifications.loading)
    const error =  useSelector<ApplicationState,Error>( state => state.notifications.error)

    const [selected, setSelected] = useState<NotificationRow[]>([])

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const [notificationLogError, setNotificationLogError] = useState(null)

    const toggle = () => {
        setSelected([])
        setNotificationLogError(null)
        setIsModalOpen(!isModalOpen)
    }

    const markNotifications = (notifications: NotificationRow[], isUnread: boolean) => {
        const ids = notifications.reduce( (acc, notification) => {
            return acc.concat(notification.duplicateIds)
        }, [] as number[])
        dispatch(updateNotifications(ids, isUnread))
    }

    const markOneUnread = (notification: NotificationRow, unread: boolean ) => {
        markNotifications([notification], unread)
    }

    const markAllUnread = (unread: boolean) => markNotifications(rows, unread)

    const markSelectedUnread = (unread: boolean) => markNotifications(selected, unread)

    const { className, labelledBy} = props

    const numUnread = rows ? rows.filter(( n => n.unread)).length : 0
    const numRead = rows ? rows.length - numUnread : 0

    const downloadLogs = async () => {
        setNotificationLogError(null)
        try {
            const response = await API.getNotificationsLog()
            const fileName = `SMRTLink Notifications Log - ${nowForFileNames()}`
            createDownload(response.data, fileName)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Notification log download error", error)
            setNotificationLogError(error)
        }
    }

    if (error) {
        if ((error as any).status !== 403) {
            return <b>Error loading notifications: {
                (notificationLogError as any)?.originalError?.message || "Request Failed"
            }</b>
        }
        return <b>Error loading notifications: Permissions Error</b>
    }

    return (
        <Fragment>

            <span onClick={toggle}>
                <span>Notifications</span>
                { numUnread > 0 &&
                    <Badge pill color="danger" className="pb-badge-text">
                        {numUnread}
                    </Badge>
                }
            </span>
            <Modal
                size="xl"
                isOpen={isModalOpen}
                toggle={toggle}
                className={className}
                aria-labelledby={labelledBy}
            >
                <ModalHeader id={labelledBy}>
                    <div className="pb-modal-header">
                        Notifications ({rows.length}):
                    </div>
                </ModalHeader>
                <ModalBody style={{height:"auto"}}>
                    <Row>
                        <Col md={8}> 
                            {`${numUnread} Unread | ${numRead} Read`} 
                            <Link to="/settings/notifications"> 
                                <span className="oi oi-cog ml-2 primary" color="primary" onClick={toggle}/> 
                            </Link>
                        </Col>
                        <Col>
                            {!API.isApiV3() && <span className="pb-link mr-1" onClick={ downloadLogs } style={{float:"right"}}>
                                View Notification Log
                            </span>}
                            { notificationLogError && displayInlineError("An error occurred downloading the log.", notificationLogError) }
                        </Col>
                    </Row>
                    <NotificationsTable
                        rows={rows}
                        onSelectionChange={setSelected}
                        loading={loading}
                        selected={selected}
                        markUnread = { markOneUnread }
                    />

                    <div>
                        <MarkAsButton className={"mr-3"}
                            selected={ selected }
                            markNotifications={ (unread: boolean) => markSelectedUnread(unread) }
                        />
                        <Button onClick = {() => markAllUnread(false)}>
                            Mark All As Read
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
