// new user management API based on Keycloak
import { User, UserValues, UserRoleData, KeycloakUser } from "../../model/user-model"
import * as keycloakService from "../../services/keycloak-service"

export interface ProgressDetails {
    totalUserCount: number
    processedUserCount: number
}

export type Progress = (progressDetails?: ProgressDetails) => ProgressDetails

const toUser = (keycloakUser: KeycloakUser, roles?: string[]): User => {
    if (roles === undefined) {
        roles = []
    }
    let userRoles = []
    if (roles.length > 0) {
        userRoles = [roles[0]]
    }
    // XXX this needs more investigation, is it AD behavior?
    let fullname = (keycloakUser.firstName === undefined) ? keycloakUser.username : keycloakUser.firstName
    let firstname = (keycloakUser.firstName === undefined) ? "" : keycloakUser.firstName
    let lastname = (keycloakUser.lastName === undefined) ? keycloakUser.username : keycloakUser.lastName
    if (!fullname.includes(" ") && firstname !== "") {
        fullname = firstname + " " + lastname
    } else if (firstname.includes(" ")) {
        firstname = keycloakUser.firstName.split(" ")[0]
    }
    return {
        userId: keycloakUser.username,
        fullName: fullname,
        username: keycloakUser.username,
        email: keycloakUser.email,
        phone: "",
        firstName: firstname,
        lastName: keycloakUser.lastName,
        roles: userRoles,
        uuid: keycloakUser.id,
    }
}

export class AdminServiceV2 {

    public isApiV2() {
        return true
    }

    // placeholder
    public cancelUserDownloads() {
        return true
    }

    private getUserListOfRole(role: string): Promise<User[]> {
        return keycloakService.getUserListOfRole(role).then(keycloakUsers => {
            return keycloakUsers.map(u => toUser(u, [role]))
        })
    }

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    public getEnabledUsers(progress?: Progress): Promise<User[]> {
        return Promise.all([
            this.getUserListOfRole("PbAdmin"),
            this.getUserListOfRole("PbLabTech"),
            this.getUserListOfRole("PbBioinformatician")
        ]).then(([admins, bioinformaticians, labtechs]) => {
            const usersWithDuplicates: User[] = [
                ...admins,
                ...bioinformaticians,
                ...labtechs
            ]
            const uniqueUsers: User[] = []
            const userUuids = new Set<string>()
            usersWithDuplicates.forEach(user => {
                if (!userUuids.has(user.uuid)) {
                    userUuids.add(user.uuid)
                    uniqueUsers.push(user)
                }
            })
            return uniqueUsers
        })
    }

    // XXX this is identical to the V1 code
    public saveUser(
        user: User,
        oldRole: string,
        { enabled, role }: UserValues
    ): Promise<User> {
        const results: Promise<void>[] = []
        let roles: UserRoleData | null = null

        if (enabled) {
            if (oldRole) {
                if (oldRole !== role) {
                    roles = {
                        added: [role],
                        deleted: [oldRole]
                    }
                }
            } else {
                roles = {
                    added: [role]
                }
            }
        } else if (!enabled && oldRole) {
            roles = {
                deleted: [oldRole]
            }
        }

        if (roles) {
            results.push(this.updateUserRoles(user.uuid, roles))
        }

        if (!results.length) {
            return Promise.resolve(user)
        }

        return Promise.all(results)
            .then(() => this.getUser(user.uuid))
    }

    // XXX this is identical to the v1 AdminService
    public getUser(
        userId: string,
        progress?: Progress,
        keycloakUser?: KeycloakUser
    ): Promise<User> {
        return this.getUserBasic(userId, progress, keycloakUser)
    }

    private getUserPromise(userId: string, keycloakUser?: KeycloakUser): Promise<KeycloakUser> {
        if (keycloakUser === undefined) {
            return keycloakService.getUser(userId)
        } else {
            return new Promise<KeycloakUser>((resolve) => resolve(keycloakUser))
        }
    }

    private getUserBasic(userId: string,
                         progress?: Progress,
                         keycloakUser?: KeycloakUser): Promise<User> {
        // FIXME it should be possible to get the roles as part of the user
        // info but I haven't figured out how to configure Keycloak to do
        // this
        const userPromise = this.getUserPromise(userId, keycloakUser)
        return Promise.all([
            userPromise,
            keycloakService.getRolesForUser(userId)
        ]).then(([keycloakUser, roles]) => {
            if (progress) {
                progress({
                    totalUserCount: progress().totalUserCount,
                    processedUserCount: progress().processedUserCount + 1
                })
            }
            return toUser(keycloakUser, roles.map(r => r.name))
        })
        .catch(() => {
            if (progress) {
                progress({
                    totalUserCount: progress().totalUserCount,
                    processedUserCount: progress().processedUserCount + 1
                })
            }
            return {
                userId: "ERROR: " + userId,
                username: "ERROR: " + userId,
                fullName: "ERROR: " + userId,
                email: "ERROR",
                phone: "ERROR",
                roles: []
            }
        })
    }

    // FIXME this is inefficient (see note above), we should try to avoid
    // multiple API calls if possible
    public getUserListForSearch(
        searchText: string,
        progress?: Progress,
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        useWildcard = true // XXX unused, present for API compatibility only
    ): Promise<User[]> {
        return keycloakService.getAllUsers(searchText).then(keycloakUsers => {
            if (progress) {
                progress({
                    totalUserCount: keycloakUsers.length,
                    processedUserCount: 0
                })
            }
            const users = keycloakUsers.map(keycloakUser =>
                this.getUserBasic(keycloakUser.id, progress, keycloakUser)
            )
            return Promise.all(users).then(users => {
                if (!users || !users.length) {
                    return []
                }
                return users
            })
        })
    }

    public getUserByName(
      username: string,
      progress?: Progress
    ): Promise<User> {
        return keycloakService.getUserListByName(username).then(keycloakUsers => {
            const users = keycloakUsers.map(keycloakUser =>
                this.getUserBasic(keycloakUser.id, progress, keycloakUser)
            )
            return Promise.all(users).then(users => {
                if (!users || !users.length) {
                    return null;
                } else {
                    return users[0];
                }
            })
        })
    }

    // unlike the WSO2 version this returns UUIDs
    public listUsers(
        username: string,
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        maxItemLimit?: number  // this is ignored
    ): Promise<string[]> {
        return keycloakService.getAllUsers(username).then(users => {
            return users.filter(user => user.username === username).map(user => user.id)
        })
    }

    private updateUserRoles(
        userId: string,
        { deleted, added }: UserRoleData
    ): Promise<void> {
        if (!deleted) {
            deleted = []
        }
        if (!added) {
            added = []
        }
        const actions = []
        if (deleted.length > 0) {
            actions.push(keycloakService.deleteUserRoles(userId, deleted))
        }
        if (added.length > 0) {
            actions.push(keycloakService.addUserRoles(userId, added))
        }
        return Promise.all(actions).then(() => undefined)
    }
}
