// This is a client model class that is converted into from two orthogonal sources that use different user models:
//  -- 1. The user lookup services described described by adminService and adminServiceV2
//  -- 2. The login mechanism that uses the smrtlink/user api
// TODO SL-8214 disentagle these two model classes.

import { UserResponse } from "../../core-components/login/store/login-types"
import { isApiV3 } from "../api"

export interface User {
  userId: string
  username?: string
  email: string
  phone?: string
  fullName?: string
  firstName?: string
  lastName?: string
  roles: string[]
  uuid?: string // corresponds to KeycloakUser.id
}

export const isAdmin = (user: User) => {
    return user.roles.includes("Internal/PbAdmin")
}

export function cleanupUsername(username: string) {
    if (!username) { return "" }
    if (!isApiV3()) { return username }
    let result = username
    const federationPrefix = "pbokta_"
    if (result.startsWith(federationPrefix)) {
        result = result.substring(federationPrefix.length)
    }
    if (result.indexOf("@") !== -1) {
        // remove email domain
        result = result.substring(0, result.indexOf("@"))
    }
    return result
}

export const userFromUserResponse = (userResponse: UserResponse) => {
  
  return {
    userId: userResponse.userId || cleanupUsername((userResponse as any).username),
    email: isApiV3() ? userResponse.email :userResponse.userEmail,
    // TODO(pfernhout) Fix AWS Cognito SSO login Kludge for missing roles
    roles: userResponse.roles,
    firstName: userResponse.firstName,
    lastName: userResponse.lastName
  }
}

export interface UserValues {
  enabled: boolean
  role: string
}

export interface UserRoleData {
  added?: string[]
  deleted?: string[]
}

// API v2.0.0, using the Keycloak server
export interface KeycloakUserRole {
  id: string
  name: string
}

export interface KeycloakUser {
  id: string
  username: string
  email: string,
  firstName?: string
  lastName?: string
}
