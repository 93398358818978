import React, { FC, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ConnectedRouterProps } from "react-router-redux"
import { NavLink } from "react-router-dom"
import { ApplicationState } from "../../core/types"

import * as CoreActions from "../../core/store/core-actions"
import { User } from "../../data/model/user-model"
import { pageNameMapping, ModuleNavMenu } from "../navbar/ModuleNavMenu"
import Navbar from "reactstrap/lib/Navbar"
import Container from "reactstrap/lib/Container"
import Nav from "reactstrap/lib/Nav"
import { UtilityNavMenu } from "../navbar/UtilityNavMenu"
import { setToken } from "../login/store/login-actions"
import { NavMenuProps } from "../navbar/NavMenuProps"
import { UserNavMenu } from "../navbar/UserNavMenu"
import { isApiV3 } from "../../data/api"
import { BrandImageLinkWithoutReload } from "../../core/utils/link-helper"
import { useComponentVersions } from "src/silos/configuration/containers/useComponentVersions"

// For developer WSO2 testing; do not set to true in production!
const developerTestingForWSO2Timeouts = false

export const HELP_ROOT = "Default_CSH.htm"

const openDocWindow = (smrtlink: any) => {
    const version = smrtlink.version.split(".").slice(0, 2).join(".")
    window.open(`https://www.pacb.com/support/software-downloads/#doc-v${version}`)
}

type Props = ConnectedRouterProps<ApplicationState>

export const NavBar: FC<Props> = (props: Props) => {

    const dispatch = useDispatch()
    const [ topLevelComponents ] = useComponentVersions()
    const smrtlink = topLevelComponents.find(component => component.id === "smrtlink")

    const access_token = useSelector<ApplicationState, string>(state => state.auth.access_token)
    const user = useSelector<ApplicationState, User>( state => state.auth.user )


    const needsChemistryBundleUpdate = useSelector<ApplicationState, boolean>( state => {
        const { chemistryBundleUpdateAvailableVersion, chemistryBundleCurrentVersion } = state.about
        return chemistryBundleUpdateAvailableVersion > chemistryBundleCurrentVersion
    })
    const needsUiBundleUpdate = useSelector<ApplicationState, boolean>( state => {
        const { uiBundleBundleUpdateAvailableVersion, uiBundleBundleCurrentVersion } = state.about
        return uiBundleBundleUpdateAvailableVersion > uiBundleBundleCurrentVersion
    })
    const pageName =  useSelector<ApplicationState, string>( state => state.core.currentPageName )

    const navBarProps: NavMenuProps = {
        user,
        needsChemistryBundleUpdate,
        needsUiBundleUpdate,
        pageName
    }

    useEffect(() => {
        const path: string = props.history.location.pathname
        let pageInfo: any

        Object.keys(pageNameMapping).forEach(key => {
            if (path.includes(key) && !path.includes("/settings")) {
                pageInfo = pageNameMapping[key]
            }
        })

        const name = pageInfo ? pageInfo.displayName : null
        dispatch(CoreActions.setCurrentPageName(name))
    })

    const route = props.history.location.pathname

    // For developer testing only of WSO2 timeouts
    function invalidateAccessToken() {
        // The purpose of this is to simulate an access token timeout.
        dispatch(setToken(
            { access_token: "BAD_ACCESS_TOKEN_TEST" }
        ))
        alert("Invalidated WSO2 access token")
    }

    // For developer testing only of WSO2 timeouts
    function invalidateRefreshToken() {
        // The purpose of this is to simulate a refresh token timeout.
        dispatch(setToken({
            access_token: "BAD_ACCESS_TOKEN_TEST",
            refresh_token: "BAD_REFRESH_TOKEN_TEST"
        }))
        alert("Invalidated WSO2 refresh token")
    }

    const allProps = {...navBarProps, ...props}
    return (
        <Navbar dark fixed="top" className="navbar-expand" role="navigation">
            <Container className="container-nav">
                {BrandImageLinkWithoutReload(process.env.PUBLIC_URL || "/")}
                {access_token && (
                    <Fragment>
                        <Nav navbar>
                            <ModuleNavMenu {...navBarProps} />
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            <UtilityNavMenu {...allProps} />
                        </Nav>
                    </Fragment>
                )}
                {developerTestingForWSO2Timeouts && (
                    <Fragment>
                        <button onClick={() => invalidateAccessToken()}>
                            Invalidate WSO2 auth token
                        </button>
                        <button onClick={() => invalidateRefreshToken()}>
                            Invalidate WSO2 refresh token
                        </button>
                    </Fragment>
                )}
                {smrtlink && (
                    <NavLink
                        to={route}
                        onClick={() => openDocWindow(smrtlink)}
                        className="nav-link"
                        title="Help Documentation for this page"
                        aria-label="Help Documentation"
                    >
                        <span style={{ color: isApiV3() ? "lightblue" : "grey", "marginLeft": "2rem" }}>
                            Help
                        </span>
                        <span className="sr-only">
                            Help Documentation for this page
                        </span>
                    </NavLink>
                )}
                { user &&
                    <UserNavMenu user={user} />
                }
            </Container>
        </Navbar>
    )
}

