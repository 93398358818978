import React, { FC, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap"

import { isAuthorized } from "../ProtectedRoute"
import { SiloIcon } from "../SiloIcon"
import { useDispatch } from "react-redux"
import * as CoreActions from "../../core/store/core-actions"
import { NavMenuProps } from "./NavMenuProps"
import { useIsSmrtlinkLite } from "../../core/config/use-is-smrtlink-lite"
import { useIsFreeMode } from "src/core/store/core-selectors"

export type Silo ="Data Management" | "Analysis" |  "Runs" | "Instruments" | "Sample Setup"

export const pageNameMapping: any = {
    "/instruments": {
        displayName: "Instruments",
        role: "labtech",
        icon: <SiloIcon silo="Instruments" isMagenta = {false} size="small"/>
    },
    "/sample-setup": {
        displayName: "Sample Setup",
        role: "labtech",
        icon: <SiloIcon silo="Sample Setup" isMagenta = {false} size="small"/>
    },
    "/runs": {
        displayName: "Runs",
        role: "labtech",
        icon: <SiloIcon silo="Runs" isMagenta = {false} size="small"/>
    },
    "/data-management": {
        displayName: "Data Management",
        role: "bioinformatician",
        icon: <SiloIcon silo="Data Management" isMagenta = {false} size="small"/>
    },
    "/analysis": {
        displayName: "SMRT Analysis",
        role: "bioinformatician",
        icon: <SiloIcon silo="Analysis" isMagenta = {false} size="small"/>
    }
}


export const ModuleNavMenu: FC<NavMenuProps> = (props: NavMenuProps) => {

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)

    const isSmrtLinkLite = useIsSmrtlinkLite()
    const isFreeMode = useIsFreeMode()

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const select = (e: any) => {
        dispatch(CoreActions.setCurrentPageName(e.target.innerText))
        setIsOpen(false)
    }
    const showMenuItem = ( silo: string) => {
        if (silo === "/analysis") {
            return !isSmrtLinkLite && !isFreeMode
        }
        if (silo === "/data-management") {
            return !isFreeMode
        }
        return true
    }
    const isValidSilo = useMemo( () => {
        let silo = null
        Object.entries(pageNameMapping).forEach( (key,val: any) => {
            if (val.displayName === props.pageName) {
                silo = key
            }
        })
        return silo && showMenuItem(silo)

    },[props.pageName])

    return (
        <Dropdown nav inNavbar className="module-nav-menu" isOpen={isOpen} toggle={toggle}>
            <DropdownToggle nav caret role="button" id="moduleDropdown">
                {isValidSilo ? props.pageName : "Select a Module" }
            </DropdownToggle>
            <DropdownMenu aria-labelledby="moduleDropdown">
                {Object.keys(pageNameMapping).map((route, index) => {
                    const pageInfo = pageNameMapping[route]
                    const enabled = isAuthorized(props.user, pageInfo.role)

                    if (!showMenuItem(route)) {
                        return null
                    }
                    return (
                        enabled && (
                            <NavLink
                                key={`module_nav_${index}`}
                                to={route}
                                onClick={select}
                                className={"dropdown-item navlink-" + route.substring(1)}
                                activeClassName="active"
                                title={pageInfo.displayName}
                            >
                                {pageInfo.icon}
                                <span className="d-inline-block ml-1">{pageInfo.displayName}</span>
                            </NavLink>
                        )
                    )
                })}
            </DropdownMenu>
        </Dropdown>
    )
}

