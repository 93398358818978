import { Reducer } from "redux"
import { InstrumentActionTypes, InstrumentState } from "./instrument-types"
import { isVegaSupported } from "../../api"
import { InstrumentType } from "../../model/instrument-type-model"

export const initialState: InstrumentState = {
    instrumentSupport: {
        supportsSequel: false,
        supportsSequel2: true,
        supportsSequel2e: true,
        supportsRevio: true,
        supportsVega: true
    },
    currentInstrumentType: "Revio"
}

export const instrumentReducer: Reducer<InstrumentState> = (state: InstrumentState = initialState, action: any) => {
    switch (action.type) {
        case InstrumentActionTypes.UPDATE_INSTRUMENT_SUPPORT:
            const instrumentSupport = action.payload
            // TODO: Remove when SMRT Link Cloud supports Vega
            if (!isVegaSupported() && instrumentSupport.supportsVega) {
                throw new Error("It should not be possible to set supportsVega = true given current system configuration")
            }
            return Object.assign({}, state, { instrumentSupport: instrumentSupport} )
        case InstrumentActionTypes.UPDATE_CURRENT_INSTRUMENT_TYPE:
            const currentInstrumentType: InstrumentType = action.payload
            // TODO: Remove when SMRT Link Cloud supports Vega
            if (!isVegaSupported() && currentInstrumentType === "Vega") {
                throw new Error("It should not be possible to set currentInstrumentType = Vega given current system configuration")
            }
            return Object.assign({}, state, { currentInstrumentType: action.payload} )
        default:
            return state
    }
}
