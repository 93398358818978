import React, { Component } from "react"
import { formatApiErrorResponse } from "../../../data/api"
import { ErrorLayout } from "./ErrorLayout"
import { RouteComponentProps, withRouter } from "react-router-dom"

// See: https://reactjs.org/docs/error-boundaries.html

interface Props extends RouteComponentProps {
}

interface State {
    hasError: boolean
    error: Error | null
    errorInfo: any
    promiseRejectionEvent: any
}

// This is used to define a global error reporting function
const windowFieldForErrorReporting = "_pb_errorBoundary_setState"

// Use this function to report an unrecoverable error to the user
export function reportError(error: Error, promiseRejectionEvent: any = null) {
    if (error.name === "RefreshTokenError") {
        // eslint-disable-next-line no-console
        console.log("reportError: ignoring RefreshTokenError")
        return
    }
    if (error.name === "Unauthorized") {
        // eslint-disable-next-line no-console
        console.log("reportError: ignoring Unauthorized")
        return
    }
    if (!window.pb_error_state && window[windowFieldForErrorReporting]) {
        window.pb_error_state = true
        // eslint-disable-next-line no-console
        console.error("reportError:", error, promiseRejectionEvent)
        window[windowFieldForErrorReporting]({
            hasError: true,
            error,
            errorInfo: null,
            promiseRejectionEvent: promiseRejectionEvent})
    } else {
        if (window.pb_error_state) {
            // eslint-disable-next-line no-console
            console.error("reportError: Additional error", error, promiseRejectionEvent)
        } else {
            // eslint-disable-next-line no-console
            console.error("reportError: Undisplayable error", error, promiseRejectionEvent)
        }
    }
}

// Use this function to report an error to the user inline on a page (like an API failure)
export function displayInlineError(message: string, error?: Error | undefined) {
    return <div className="mt-2">
        <span style={{ color: "red" }}>{message}</span>
        { error && <span style={{ color: "red", marginLeft: "1rem", fontSize: "0.8rem" }}>
            {formatApiErrorResponse(error as any)}
        </span> }
    </div>
}

class ErrorBoundaryComponent extends Component<Props, State> {
    listener: any = null

    constructor(props) {
        super(props)
        this.state = { hasError: false, error: null, errorInfo: null, promiseRejectionEvent: null }
    }

    componentDidMount() {
        this.listener = (promiseRejectionEvent) => {
            // eslint-disable-next-line no-console
            console.error("unhandled promise rejection")
            reportError(new Error("unhandled promise rejection"), promiseRejectionEvent)
        }
        window.addEventListener("unhandledrejection", this.listener, {once: true})
        window.onerror = (msg, url, lineNo, columnNo, error) => {
            // eslint-disable-next-line no-console
            console.error("Uncaught error: ", msg, url, lineNo, columnNo, error)
            reportError(error)
        }
        window[windowFieldForErrorReporting] = (state) => this.setState(state)
    }

    componentWillUnmount() {
        if (this.listener) { window.removeEventListener("unhandledrejection", this.listener) }
        // Leave the window.onerror handler in place; it will be overwritten if component mounted again
        this.listener = null
        window[windowFieldForErrorReporting] = undefined
    }

    componentDidUpdate(prevProps, prevState): void {
        if (prevProps && prevProps.location.key !== this.props.location.key) {
            if (prevState.hasError === true) {
                this.setState({ hasError: false, error: null, errorInfo: null, promiseRejectionEvent: null })
            }
        }
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error }
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info)
        this.setState({ hasError: true, error: error, errorInfo: info })
    }

    errorNameForPromiseRejection() {
        if (!this.state.promiseRejectionEvent || !this.state.promiseRejectionEvent.reason) { return "" }
        return "Unhandled promise rejection: " + this.state.promiseRejectionEvent.reason.message
    }

    errorInfoForPromiseRejection() {
        if (!this.state.promiseRejectionEvent) { return "" }
        return {componentStack: this.state.promiseRejectionEvent.reason.stack}
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-page">
                    <ErrorLayout
                        error={this.state.error || this.errorNameForPromiseRejection()}
                        errorInfo={this.state.errorInfo || this.errorInfoForPromiseRejection()}
                    />
                </div>
            )
        }

        return this.props.children
    }
  }

  export const ErrorBoundary = withRouter(ErrorBoundaryComponent)