import { Tenant } from "src/data/model/tenant-model"

export const TenantActionTypes = {
  API_ATTEMPT: "@@tenant/API_ATTEMPT",
  API_FAILURE: "@@tenant/API_FAILURE",
  SET_TENANT: "@@tenant/SET_TENANT",
}

export interface TenantState {
  readonly tenant: Tenant
  readonly loading: boolean
  readonly error: Error | null
}
