import React, { FunctionComponent, Fragment, ReactNode } from "react"
import Button from "reactstrap/lib/Button"
import Collapse from "reactstrap/lib/Collapse"
import classnames from "classnames"

interface Props {
    label?: string
    // eslint-disable-next-line no-undef
    headerRenderer?: () => JSX.Element
    children: ReactNode
    isOpen?: boolean
    setIsOpen?: (isOpen: boolean) => void
    labelClass?: string
}

export const ControlledChevronCollapse: FunctionComponent<Props>  = (props: Props) => {

    const {isOpen, setIsOpen, label, headerRenderer, labelClass} = props

    const onToggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Fragment>
                <div className="collapse-header">
                    <Button color="link" onClick={onToggle} className="ml-3">
                        { isOpen ?
                            <span className="oi oi-chevron-bottom"></span> :
                            <span className="oi oi-chevron-right"></span>
                        }
                        { label &&
                            <span className={classnames(`btn-text ${labelClass}`)}>{label}</span>
                        }
                    </Button>

                    { headerRenderer && headerRenderer() }
                    </div>


            <Collapse isOpen={isOpen}>
                <div className="collapse-inner ml-3 mr-3">
                    {props.children}
                </div>
            </Collapse>
        </Fragment>
    )
}
