import React, { Component } from "react"
import { connect } from "react-redux"
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    CustomInput,
    Spinner
} from "reactstrap"
import { User } from "../../data/model/user-model"
import { ResourceNotFoundError } from "../../data/api"
import { VersionManifestService } from "../../data/services/version-manifest-service"
import { EulaService, Eula } from "../../data/services/eula-service"
import { errorHandler } from "../../core/utils/error-handler"
import { ApplicationState } from "../../core/types"
import { isAuthorized } from "../ProtectedRoute"
import { ChevronCollapse } from "./ChevronCollapse"

enum EulaSendingState {
    unsent = "unsent",
    sentAndWaitingForServer = "sentAndWaitingForServer",
    acknowledgedByServer = "acknowledgedByServer"
}

interface Props {
    user: User
}

interface State {
    isOpen: boolean
    isNotify: boolean
    isSendUsage: boolean
    isEulaChecked: boolean
    userWhenEulaChecked: User | null
    eulaSendingState: EulaSendingState
    smrtlinkVersion: string
}

const INITIAL_STATE: State = {
    isOpen: false,
    isNotify: true,
    isSendUsage: true,
    isEulaChecked: false,
    userWhenEulaChecked: null,
    smrtlinkVersion: "",
    eulaSendingState: EulaSendingState.unsent
}

export class ModalPostInstallPromptComponent extends Component<Props> {
    state = INITIAL_STATE

    componentDidMount() {
        this.showEulaDialogIfNecessary(this.props)
    }

    hideModal = () => {
        this.setState({
            isOpen: false
        })
    }

    showModal = () => {
        const eulaSendingState = EulaSendingState.unsent
        this.setState({
            eulaSendingState,
            isOpen: true
        })
    }

    sendEula = () => {
        const { user } = this.props
        const { isNotify, isSendUsage } = this.state
        const eula: Eula = {
            user: user.userId,
            enableInstallMetrics: isNotify,
            enableJobMetrics: isSendUsage
        }
        const eulaSendingState = EulaSendingState.sentAndWaitingForServer
        this.setState({ eulaSendingState })
        EulaService.addEula(eula).then(
            () => {
                setTimeout(() => {
                    const eulaSendingState =
                        EulaSendingState.acknowledgedByServer
                    this.setState({ eulaSendingState }, () => {
                        this.hideModal()
                    })
                },         1500)
            },
            error => {
                this.hideModal()
                errorHandler(null, error)
                throw error
            }
        )
    }

    showEulaDialogIfNecessary = (theProps: Props) => {
        const { user } = theProps
        const { isEulaChecked, userWhenEulaChecked } = this.state
        if (user && (!isEulaChecked || userWhenEulaChecked !== user) && isAuthorized(user, "admin")) {
            this.setState({ isEulaChecked: true, userWhenEulaChecked: user })
            VersionManifestService.getVersionManifest().then(
                versionManifest => {
                    if (versionManifest) {
                        const smrtlinkVersion = versionManifest["smrtlink"]
                            ? versionManifest["smrtlink"].version
                            : "unknown"
                        this.setState({ smrtlinkVersion })
                        EulaService.getEula(smrtlinkVersion).then(
                            () => {
                                const eulaSendingState =
                                    EulaSendingState.acknowledgedByServer
                                this.setState({ eulaSendingState })
                            },
                            error => {
                                if (error instanceof ResourceNotFoundError) {
                                    this.showModal()
                                }
                            }
                        )
                    }
                }
            )
        }
    }

    prepareTechSupportInformation = eula => {
        return {
            eventTypeId: "smrtlink.eula",
            messageVersion: "1",
            message: eula
        }
    }

    onYesNotify = () => {
        this.setState({ isNotify: true })
    }

    onNoNotify = () => {
        this.setState({ isNotify: false })
    }

    onYesSendUsage = () => {
        this.setState({ isSendUsage: true })
    }

    onNoSendUsage = () => {
        this.setState({ isSendUsage: false })
    }

    isSendUsageDisabled = () => {
        const { eulaSendingState } = this.state
        return eulaSendingState !== EulaSendingState.unsent
    }

    componentDidUpdate() {
        if (this.props.user && (!this.state.isEulaChecked || this.state.userWhenEulaChecked !== this.props.user)) {
            this.showEulaDialogIfNecessary(this.props)
        }
    }

    render() {
        const {
            smrtlinkVersion,
            eulaSendingState,
            isSendUsage,
            isNotify
        } = this.state
        return (
            <Modal
                size="lg"
                isOpen={this.state.isOpen}
                toggle={this.hideModal}
                aria-label="Notification of successful installation"
                backdrop="static"
                keyboard={false}
            >
                <Form>
                    <ModalBody>
                        <h2 className="font-weight-bold my-3">
                            SMRT<sup>®</sup> Link version {smrtlinkVersion} is
                            successfully installed.
                        </h2>
                        <p>
                            Would you like to notify PacBio of the successful
                            installation? This notification includes the server
                            operating system, DNS name, user name, job
                            management system, and basic SMRT Link
                            configuration information.
                        </p>
                        <CustomInput
                            type="radio"
                            name="isNotify"
                            id="isYesNotify"
                            label="Notify PacBio of the successful installation."
                            checked={isNotify}
                            onClick={this.onYesNotify}
                            onChange={() => {}}
                            disabled={
                                eulaSendingState !== EulaSendingState.unsent
                            }
                        />
                        <CustomInput
                            type="radio"
                            name="isNotify"
                            id="isNoNotify"
                            label="Do not notify PacBio of the successful installation.
                                Selecting this option will also turn off ability to send the following to PacBio via SMRT Link:
                                installation troubleshooting logs, analysis failure logs,
                                    and SMRT Link usage information (described below)."
                            checked={!isNotify}
                            onChange={() => {}}
                            onClick={this.onNoNotify}
                            disabled={
                                eulaSendingState !== EulaSendingState.unsent
                            }
                        />
                        <hr />
                        <p>
                            For faster technical support and to help us improve
                            the quality of our products, would you like to send
                            ongoing SMRT Link analysis usage information to
                            PacBio? The information includes the following items.{" "}
                            <strong>
                                It does not include sample names or sequence
                                data.
                            </strong>
                            <ChevronCollapse label="">
                                <ul>
                                    <li>
                                        {"Per run: Movie ID, Polymerase Read Bases, Polymerase Reads, "+
                                        "Polymerase Read N50, Polymerase Read Length (mean), Subread Length "+
                                        "(mean), Subread N50, Longest Subread Length (mean), Longest Subread "+
                                        "N50, Unique Molecular Yield,"+
                                        " (11-100bp) %, Local Base Rate, Number of of Control Reads, Control Read"+
                                        " Length Mean, Control Read Concordance Mean, Control Read Concordance"+
                                        " Mode, ≥Q20 Reads, ≥Q20 Yield (bp), ≥Q20 Read Length (mean, bp), ≥Q20"+
                                        " Read Quality (median), ≥Q20 Number of Passes (mean), <Q20 Reads, <Q20"+
                                        " Yield (bp), <Q20 Read Length (mean, bp), <Q20 Read Quality (median),"+
                                        " Productive ZMWs, Productivity 0, Productivity 1, Productivity 2, Unique"+
                                        " Barcodes, Barcoded Reads, Unbarcoded Reads, Mean Reads, Max. Reads, Min."+
                                        " Reads, Mean Read Length, Mean Longest Subread Length."}
                                    </li>
                                    <li>
                                        {"Per analysis: Date created, date updated, state (successful or failed) "+
                                        "analysis ID number, movie ID number, SMRT Link version, analysis application"+
                                        " name (e.g. HGAP 4, Long Amplicon Analysis, etc.), whether the analysis "+
                                        "triggered other analyses (yes/no), and whether the analysis is visible in the UI (yes/no)."}
                                    </li>
                                </ul>
                            </ChevronCollapse>
                        </p>
                        <CustomInput
                            type="radio"
                            name="isSendUsage"
                            id="yesSendUsage"
                            label="Send ongoing SMRT Link analysis usage information to PacBio."
                            checked={isSendUsage}
                            disabled={this.isSendUsageDisabled()}
                            onChange={() => {}}
                            onClick={this.onYesSendUsage}
                        />
                        <CustomInput
                            type="radio"
                            name="isSendUsage"
                            id="noSendUsage"
                            label="Do not send ongoing SMRT Link analysis usage information to PacBio."
                            checked={!isSendUsage}
                            disabled={this.isSendUsageDisabled()}
                            onChange={() => {}}
                            onClick={this.onNoSendUsage}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={
                                eulaSendingState !== EulaSendingState.unsent
                            }
                            onClick={this.sendEula}
                        >
                            Save
                        </Button>
                        {eulaSendingState ===
                            EulaSendingState.sentAndWaitingForServer && (
                            <Spinner
                                size="sm"
                                className="ml-3"
                                color="primary"
                            />
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
    user: auth.user
})

export const ModalPostInstallPrompt = connect(
    mapStateToProps,
    null
)(ModalPostInstallPromptComponent)
