import { createSelector } from "reselect"
import { DataType } from "../../../model/data-type"
import { BatchMode, CameFrom } from "../../../model/analysis-settings-constants"
import { ENTRY_ID_TO_DATATYPE } from "../../../model/pipeline-template-models"
import { JobPrefillInfo } from "../../../services/job-copy-service"
import { isAnalysisStateBatchCustom, isAnalysisStateBatchIdentical, StepOneSelections } from "./new-analysis-types"
import { Dataset } from "../../../model/dataset-model"


export const DEFAULT_STEP_ONE_SELECTIONS: StepOneSelections = {
    analysisName: "",
    dataType: DataType.DEFAULT_DATATYPE,
    canSelectDataType: true,
    batchMode: BatchMode.Identical,
    cameFrom: CameFrom.Unknown,
    selectedParentUuid: null,
    selectedDatasets: [],
    pipelineId: null,
    jobPrefill: null,
    activeDatasetIndex: 0,
    isLoaded: false
}

const stepOne = state => state.stepOne

export const hasChildDatasetsSelected = createSelector(
    stepOne,
    (state) => {
        if (state.selectedDatasets.length === 0) {
            return false
        }
        for (let dataset of state.selectedDatasets) {
            if (dataset.parentUuid) {
                return true
            }
        }
        return false
    }
)

export const hasParentDatasetsSelected = createSelector(
    stepOne,
    (state) => {
        if (state.selectedDatasets.length === 0) {
            return false
        }
        for (let dataset of state.selectedDatasets) {
            if (dataset.parentUuid) {
                return false
            }
        }
        return true
    }
)

export const getStep1StateForForm = createSelector(
    stepOne,
    (state) => ({
        analysisName: state.analysisName,
        batchMode: state.batchMode
    })
)

export const isCustomBatchJob = createSelector(
    stepOne,
    isAnalysisStateBatchCustom
)

export const isIdenticalBatchJob = createSelector(
    stepOne,
    isAnalysisStateBatchIdentical
)

export const getStep1StateForDataset = (dataset: Dataset) => ({
    ...DEFAULT_STEP_ONE_SELECTIONS,
    analysisName: "Analysis of " + dataset.name,
    cameFrom: CameFrom.DataManagement,
    selectedParentUuid: dataset.parentUuid || undefined
})

export const getJobPrefillAndStep1State = (jobPrefill: JobPrefillInfo):StepOneSelections => ({
    ...DEFAULT_STEP_ONE_SELECTIONS,
    analysisName: jobPrefill.analysisName,
    dataType: ENTRY_ID_TO_DATATYPE[jobPrefill.primaryEntryPointId],
    canSelectDataType: false,
    selectedParentUuid: jobPrefill.parentDatasetUuid,
    cameFrom: CameFrom.CopyAnalysis,
    pipelineId: jobPrefill.jsonSettings.pipelineId,
    isLoaded: true,
    jobPrefill
})

