import { Action, ActionCreator } from "redux"
import * as API from "../../../api"
import { AnalysisSelectorTypes, DEFAULT_ID_FIELD } from "../analysis-selector/analysis-selector-types"
import { Job } from "../../../model/job-model"
import { apiCache } from "../../../services/api-cache"
import { SelectionMap } from "../../../../core-components/shared/DrillDownTable/drill-down-types"
import { ApiResponse } from "apisauce"
import { Project } from "../../../model/project-model"
import { FilterModel, SortModel } from "../../../services/grid-storage-service"

export function getOperator(type: string) {
    const result = {
        equals: "",	                // Text, Number, Date
        notEqual: "not:",	        // Text, Number, Date
        contains: "like:",	        // Text
        notContains: "unlike:",     // Text
        startsWith: "start:",	    // Text
        endsWith: "end:",	        // Text
        lessThan: "lt:",	        // Number, Date
        lessThanOrEqual: "lte:",	// Number
        greaterThan: "gt:",         // Number, Date
        greaterThanOrEqual: "gte:", // Number
        inRange: "range:",	        // Number, Date
        in: "in:",                  // Text
        empty: "???:"               // ?choose one
    }[type]
    if (result === undefined) {
        return "???:"
    }
    return result
}

export const ArrayIndicator = "PBArray:"

export function convertQuerySortAndFilterToServerQuery(rawTextQuery, sortModel, filterModel, options) {
    if (rawTextQuery) {
        options.rawText = rawTextQuery
    }
    if (sortModel && sortModel.length) {
        options.orderBy = sortModel[0].colId
        options.sortDescending = (sortModel[0].sort === "desc")
    }
    for (const key of Object.keys(filterModel)) {
        const filter = filterModel[key]
        if (filter.filterType !== "date") {
            let filterText = String(filter.filter)
            if (filterText.includes("+")) {
                filterText = filterText.replace(/\+/g, "%2B")
            }
            options[key] = getOperator(filter.type) + filterText
            if (("" + filter.filter).startsWith(ArrayIndicator)) {
                // Workaround for ag-grid not liking "in" operator without more configuration
                options[key] = "in:" + filter.filter.substring(ArrayIndicator.length)
            }
            if (filter.type === "inRange") {
                options[key] = options[key] + "," + filter["filterTo"]
            }
        } else {
            options[key] = getOperator(filter.type) + filter["dateFrom"].split(" ")[0]
            if (filter.type === "inRange") {
                options[key] = options[key] + "," + filter["dateTo"].split(" ")[0]
            }
        }
    }
}

export const makeJobsPromise = async (
    rawTextQuery: string,
    parentMultiJobId: number,
    project: Project,
    isFlatMode: boolean,
    /* eslint-disable-next-line no-unused-vars */
    filterModel: FilterModel,
    sortModel: SortModel,
    start: number,
    limit: number,
    additionalServerSideFilter?: any
): Promise<ApiResponse<Job[]>> => {

    const options: any = {
        marker: start,
        limit,
        ...additionalServerSideFilter
    }

    convertQuerySortAndFilterToServerQuery(rawTextQuery, sortModel, filterModel, options)

    if (isFlatMode) {
        return Boolean(project) ?
            await API.nJobsForFlatMode(options, project.id) :
            await API.nJobsForFlatMode(options)
    }

    return Boolean(project) ?
        parentMultiJobId ?
            await API.childJobs({parentMultiJobId, options, projectId: project.id}) :
            await API.analysisJobs(options, project.id)
        :
        parentMultiJobId ?
            await API.childJobs({parentMultiJobId, options}) :
            await API.analysisJobs(options)
}

export const makeJobsFromResponse = async (response): Promise<Job[]> => {
    const templates = await apiCache.getPipelineTemplates()
    let jobs: Job[] = []

    if (templates) {
        jobs = response.data.map(job => ({
            ...job,
            applicationName: job.applicationName,
            pipelineId: job.subJobTypeId
        }))
    }

    return jobs
}

export const clearAnalysisSelectionMap: ActionCreator<Action> = () => ({
    type: AnalysisSelectorTypes.SET_SELECTION_MAP,
    payload: SelectionMap.fromField(DEFAULT_ID_FIELD)
})

export const setAnalysisSelectionMap: ActionCreator<Action> = (selectionMap: SelectionMap) => ({
    type: AnalysisSelectorTypes.SET_SELECTION_MAP,
    payload: selectionMap ? selectionMap.clone() : null
})

export const setIsFlatMode: ActionCreator<Action> = (isFlatMode: boolean) => ({
    type: AnalysisSelectorTypes.SET_IS_FLAT_MODE,
    payload: isFlatMode
})
