import { Facet } from "../facets/facet"
import { DatasetType } from "./dataset-type-model"
import { PARENT_SUBREAD_FACET, CHILD_SUBREAD_FACET, FLATTENED_SUBREAD_FACET } from "../facets/subread"
import { PARENT_CCS_READ_FACET, CHILD_CCS_READ_FACET, FLATTENED_CCS_READ_FACET } from "../facets/ccs-read"
import { HDF_SUBREAD_FACET } from "../facets/hdf-subread"
import { BARCODE_FACET } from "../facets/barcode"
import { REFERENCE_FACET } from "../facets/reference"
import { GMAP_REFERENCE_FACET } from "../facets/gmap-reference"
import { ALIGNMENT_FACET } from "../facets/alignment"
import { BED_FACET } from "../facets/bedfile"

// DataType is a view-tier extension of the services-tier DatasetType model.
// The parallel models were more obviously necessary when "project" was a DataType (before SL-3349)
// but it's probably still useful to maintain the distinction.

export const PRIMARY_FILE_TYPES = [
    DatasetType.SUBREAD.filetype,
    DatasetType.CONSENSUS_READ.filetype
]

export class DataType {

    public label: string

    constructor (
        public facet: Facet,
        public datasetType: DatasetType,
        public childFacet?: Facet,
        public flattenedFacet?: Facet
     ) {
         this.label = datasetType.title
     }

    public isSame(other: DataType): boolean {
        return this.datasetType.filetype === other.datasetType.filetype
    }

    public shortName(): string {
        return this.datasetType.shortName
    }

    public isPrimary(): boolean {
        return PRIMARY_FILE_TYPES.includes(this.datasetType.filetype)
    }

    static SUBREAD_TYPE = new DataType(
        PARENT_SUBREAD_FACET,
        DatasetType.SUBREAD,
        CHILD_SUBREAD_FACET,
        FLATTENED_SUBREAD_FACET
    )

    static CCS_READ_TYPE = new DataType(
        PARENT_CCS_READ_FACET,
        DatasetType.CONSENSUS_READ,
        CHILD_CCS_READ_FACET,
        FLATTENED_CCS_READ_FACET
    )

    static HDF_SUBREAD_TYPE = new DataType(
        HDF_SUBREAD_FACET,
        DatasetType.HDF_SUBREAD
    )

    static BARCODE_TYPE = new DataType(
        BARCODE_FACET,
        DatasetType.BARCODE
    )

    static REFERENCE_TYPE = new DataType(
        REFERENCE_FACET,
        DatasetType.REFERENCE
    )

    static GMAPREFERENCE_TYPE = new DataType(
        GMAP_REFERENCE_FACET,
        DatasetType.GMAP_REFERENCE
    )

    static ALIGNMENT_TYPE = new DataType(
        ALIGNMENT_FACET,
        DatasetType.ALIGNMENT
    )

    static CONSENSUS_ALIGNMENT_TYPE = new DataType(
        ALIGNMENT_FACET,
        DatasetType.CONSENSUS_ALIGNMENT
    )

    static BED_TYPE = new DataType(
        BED_FACET,
        DatasetType.BED
    )

    static ALL_DATATYPES: DataType[] = [
        DataType.SUBREAD_TYPE,
        DataType.CCS_READ_TYPE,
        DataType.BARCODE_TYPE,
        DataType.REFERENCE_TYPE,
        DataType.GMAPREFERENCE_TYPE,
        DataType.ALIGNMENT_TYPE,
        DataType.CONSENSUS_ALIGNMENT_TYPE,
        DataType.BED_TYPE
    ]
    static DEFAULT_DATATYPE = DataType.CCS_READ_TYPE

    static byDatasetTypeShortName(shortName: string): DataType {
        for (let dataType of DataType.ALL_DATATYPES) {
            if (dataType.datasetType.shortName === shortName) {
                return dataType
            }
        }
        throw new Error("Error: DataType.byDatasetTypeShortName does not recognize shortName: " + shortName)
    }

    static byDatasetTypeFileName(fileType: string): DataType {
        for (let dataType of DataType.ALL_DATATYPES) {
            if (dataType.datasetType.filetype === fileType) {
                return dataType
            }
        }
        throw new Error("Error: DataType.byDatasetTypeFileName does not recognize fileType: " + fileType)
    }

    static fileTypeToShortName(fileType: string): string {
        const dataType = DataType.byDatasetTypeFileName(fileType)
        return dataType.shortName()
    }
}
