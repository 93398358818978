const formatDecimalFixed = (number: number, fractionDigits?: number) => {
    const locale = localStorage.getItem("decimalSeparator") === "," ? "es-ES" : "en-us"
    let options = {}
    if (fractionDigits != undefined) {
        options = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }
    }
    const formatter = new Intl.NumberFormat(locale, options)
    return formatter.format(number)
}

export const numberFormatter = (number: number, precision?: number) => {
    if (number) {
        let formattedNumber = formatDecimalFixed(number, precision)
        return formattedNumber
    } else {
        return number
    }
}

const baseFormattedString = (num: number, places: number, bases: string[],
    baseSize: number, zeroPlacesForLowestBase: boolean = false): string => {
    if (isNaN(num)) {
        return num + ""
    } else if (num === null) {
        return ""
    } else {
        let unitIndex = 0
        if (num >= 1) {
            unitIndex = Math.floor(Math.log(num) / baseSize)
            num = num / Math.pow(1000, unitIndex)
        }

        // Some units of measurement normally don't present fractionated units
        if (zeroPlacesForLowestBase && unitIndex === 0) {
            places = 0
        }

        return `${numberFormatter(num, places)} ${bases[unitIndex]}`
    }
}

export const baseSizeString = (num: number, places: number = 0): string =>
    baseFormattedString(num, places, ["bp", "kb", "Mb", "Gb", "Tb", "Pb"], Math.log(1000))

export const sizeString = (num: number, places: number = 0): string =>
    baseFormattedString(num, places, ["", "K", "M", "G", "T", "P"], Math.log(1000))

export const fileSizeString = (bytes: number, places: number = 0): string =>
    baseFormattedString(bytes, places, ["bytes", "KB", "MB", "GB", "TB", "PB"],
        Math.log(1024), true)
