import React, { FunctionComponent, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { RouteComponentProps } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../store/login-actions"
import { getSSOLoginURL, getUseSSO, isApiV3 } from "../../../data/api"
import { ApplicationState } from "../../../core/types"

const BODY_THEME_CLASS = "btheme-welcome"

interface Props extends RouteComponentProps<{}> {
    search: string
}

export const AuthenticatedPage: FunctionComponent<Props> = (props: Props) => {

    useEffect(() => {
        document.body.classList.add(BODY_THEME_CLASS)
        return function cleanup() {
            document.body.classList.remove(BODY_THEME_CLASS)
        }
    })

    const dispatch = useDispatch()
    const errorMessage = useSelector<ApplicationState, string>( state => state.auth.errorMessage )

    const searchParams = new URLSearchParams(props.location.search)
    const code = searchParams.get("code")

    useEffect(() => {
        if (getUseSSO() && code) {
            dispatch(login({code, username: "", password: ""}))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container className="login-welcome-page container-welcome">
            <Row>
                <Col className="welcome-content" md={4}>
                    { !getUseSSO() && <p>SSO is not enabled in environment variables</p> }
                    { !code && <p>Missing authorization code</p> }
                    { errorMessage && <p>Login issue: {errorMessage}</p>}
                    { errorMessage && getUseSSO() && getSSOLoginURL() &&
                        <p><a style={{color: "white"}} href={getSSOLoginURL()}>Try again via SSO</a></p>
                    }
                </Col>
                <Col className="welcome-title" md={8}>
                    <h1 className="text-right mb-0">
                        <span className="display-2 d-block">Welcome to</span>
                        <span className="display-1">
                            { isApiV3() ? "SMRT Link Cloud" : "SMRT Link" }
                        </span>
                    </h1>
                </Col>
            </Row>
        </Container>
    )
}
