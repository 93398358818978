import { Reducer } from "redux"
import { TenantState, TenantActionTypes } from "./tenant-types"

const initialState: TenantState = {
    tenant: null,
    loading: false,
    error: null
}

export const tenantReducer: Reducer<TenantState> = (
    state: TenantState = initialState,
    action: any
) => {
    switch (action.type) {
        case TenantActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case TenantActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case TenantActionTypes.SET_TENANT:
            return Object.assign({}, state, {
                tenant: action.payload,
                loading: false,
                error: null
            })
        default:
            return state
    }
}
