import { CoreActionTypes } from "./core-types"
import { Action, ActionCreator } from "redux"

const SUBSCRIPTION_TYPE_FREE = "free"

export const setCurrentPageName: ActionCreator<Action> = (payload: string) => ({
  type: CoreActionTypes.SET_CURRENT_PAGE_NAME, payload
})

export const setIsFreeMode: ActionCreator<Action> = (isDebugFreeMode: boolean, tenantSubscriptionType: string) => ({
  type: CoreActionTypes.SET_IS_FREE_MODE,
  payload: isDebugFreeMode || (tenantSubscriptionType === SUBSCRIPTION_TYPE_FREE)
})
