import React, { FunctionComponent, useState, ReactNode } from "react"
import { ControlledChevronCollapse } from "./ControlledChevronCollape"

interface Props {
    label: string
    children: ReactNode
    initialIsOpen?: boolean
    labelClass?: string
}

export const ChevronCollapse: FunctionComponent<Props>  = (props: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(Boolean(props.initialIsOpen))
    return (
        <ControlledChevronCollapse
            label = { props.label }
            labelClass = { props.labelClass }
            isOpen = { isOpen }
            setIsOpen = { setIsOpen }
        >
            { props.children }
        </ControlledChevronCollapse>
    )
}
