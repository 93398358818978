import React, { Fragment, FunctionComponent } from "react"
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import { User } from "../../data/model/user-model"
import { useDispatch, useSelector } from "react-redux"
import * as AuthActions from "../login/store/login-actions"
import { ApplicationState } from "../../core/types"
import { isApiV3 } from "src/data/api"
import { Tenant } from "src/data/model/tenant-model"
import * as _ from "lodash"


interface Props {
    user: User
}
export const UserNavMenu: FunctionComponent<Props> = (props: Props) => {

    const dispatch = useDispatch()
    const { user }= props
    const tenant = useSelector<ApplicationState, Tenant>( state => state.tenant.tenant)

    const logOut = () => {
        dispatch(AuthActions.logout())
    }

    const getCurrentRole = (roles: string[]) => {
        const roleNameMap: any = {
            Admin: "Admin",
            Bioinformatician: "Bioinformatician",
            LabTech: "Lab Tech",
            subscriber: "subscriber",
            everyone: "Everyone"
        }
        const orderedRoles = ["Admin", "Bioinformatician", "LabTech", "everyone"]
        const _roles = roles.map(role => (role || "").slice(9).replace(/Pb/, ""))

        for (let orderedRole of orderedRoles) {
            if (_roles.indexOf(orderedRole) !== -1) {
                return roleNameMap[orderedRole]
            }
        }
    }

    const userDisplay = isApiV3() ? 
        ( user.email || user.userId) :
        user.userId

    return (
        <UncontrolledDropdown nav inNavbar className="user-nav-menu  btn">
            <DropdownToggle nav role="button" id="utilityDropdown">
                <span className="navbar-text small ml-4">
                    { !_.isEmpty(tenant) && <Fragment>
                        <span className="pb-light-magenta">{tenant.tenantName}</span>
                        <span>{" | "}</span>
                    </Fragment>  } 
                    {userDisplay} ({getCurrentRole(user.roles)}) 
                </span>
            </DropdownToggle>

            <DropdownMenu aria-labelledby="userDropdown">
                <DropdownItem className="btn" onClick={logOut}>
                    <span className="oi oi-account-logout" />
                    <span className="ml-1">Sign Out</span>
                </DropdownItem>
            </DropdownMenu>

        </UncontrolledDropdown>
    )
}
