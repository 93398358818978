import { useEffect, useState } from "react";
import { isApiV3 } from "src/data/api";
import { ComponentVersionInfo, VersionManifestService } from "src/data/services/version-manifest-service";

export const useComponentVersions = () => {

    const [ topLevelComponents, setTopLevelComponents ] = useState<ComponentVersionInfo[]>([])
    const [ allComponents, setAllComponents ] = useState<ComponentVersionInfo[]>([])

    const processVersion = async () => {
        try {
            const versionManifest = await VersionManifestService.getVersionManifest()

            const categoryIds = ["smrtlink", "smrttools", "smrtlink-analysisservices-gui", "smrtview", "smrtinub", "smrtimisc"]
            const topLevelComponents = []
            for (let id of categoryIds) {
                const componentInfo = versionManifest[id]
                if (componentInfo) {
                    topLevelComponents.push(componentInfo)
                }
            }
            setTopLevelComponents(topLevelComponents)

            const allComponents = []
            if (!isApiV3()) {
                VersionManifestService.getComponentsInHierarchicalOrderWithDepth(
                    versionManifest,
                    "smrtlink",
                    allComponents,
                    0
                )
            } else {
                VersionManifestService.getComponentsHierarchicalSimple(
                    versionManifest,
                    allComponents)
            }
            setAllComponents(allComponents)
        } catch {
            // Do nothing if error
        }
    }

    useEffect(() => {
        processVersion()
    // eslint-disable-next-line
    }, [])

    return [topLevelComponents, allComponents]
  }
