// Model for data expected by jobs POST endpoints
// Object Hierarchy:
// - JobOptions
// --- JobOptionEntryPoint[]
// --- JobTaskOption[]
// - MultiJobOptions
// --- DeferredJobOptions[]
// ------- DeferredJobOptionEntryPoint[]
export type AnyJobOptions =
    JobOptions |
    MergeJobOptions |
    CopyDatasetJobOptions |
    ExportDatasetJobOptions |
    ExportReportJobOptions |
    DeleteDatasetJobOptions |
    ExportAnalysisJobOptions |
    ExportReportJobOptions |
    DeleteDatasetJobOptions |
    DeleteJobOptions |
    TechSupportStatusOptions |
    TechSupportJobOptions |
    {} // some job posts have no options, e.g. restart-gui

export interface JobOptions { // Swagger: PbsmrtpipeJobOptions
    pipelineId: string
    taskOptions: JobTaskOption[]
    entryPoints: JobOptionEntryPoint[]
    workflowOptions?: any[]
    name?: string
    projectId?: number
    presetId?: string
}

export interface JobOptionEntryPoint { // Swagger: BoundServiceEntryPoint
    fileTypeId: string
    entryId: string
    datasetId: any // string or int
}

export interface MultiJobOptions { // Swagger: MultiAnalysisJobOptions
    description: string
    name: string
    projectId: number
    jobs: DeferredJob[]
    submit: boolean,
    isNested?: boolean
}

export interface DeferredJob { // Swagger: DeferredJob
    pipelineId?: string
    entryPoints?: DeferredJobOptionEntryPoint[]
    taskOptions?: JobTaskOption[]
    workflowOptions?: any[]
    name?: string
    projectId?: number
    presetId?: string
    description?: string
}

export interface DeferredJobOptionEntryPoint { // Swagger: DeferredEntryPoint
    fileTypeId: string
    entryId: string
    uuid: string // must be the dataset uuid.  does not need to exist yet in the system.
}

export interface JobTaskOption { // Swagger: ServiceOption
    id: string // one.two.id
    optionTypeId: string // one.two.id
    value: any
}

export class MergeJobOptions {
    constructor(
        public ids: number[],
        public name?: string,
        public autoMergeMode?: boolean
    ) { }
}

export interface CopyJobDatasetFilter {
    name: string
    operator: string
    value: string
}

export class CopyDatasetJobOptions {
    constructor(
        public name: string,
        public datasetId: number,
        public filters: CopyJobDatasetFilter[][],
        public wellSampleName?: string,
        public bioSampleName?: string
    ) { }
}

export class ExportDatasetJobOptions {
    constructor(
        public ids: number[],
        public outputPath: string,
        public deleteAfterExport: boolean,
        public generateReportPdfs: boolean
    ) { }
}

export class ExportReportJobOptions {
    constructor(
        public ids: number[]
    ) { }
}

export class DeleteDatasetJobOptions {
    constructor(
        public ids: number[],
        public removeFiles: boolean
    ) { }
}

export class ExportAnalysisJobOptions {
    constructor(
        public includeEntryPoints: boolean,
        public ids: number[],
        public outputPath: string
    ) { }
}

export class DeleteJobOptions {
    constructor(
        public jobId: string, // confusing as it is, this post property should be filled with job.Uuid, not job.Id
        public removeFiles: boolean,
        public dryRun: boolean
    ) { }
}

/* eslint @typescript-eslint/no-useless-constructor: "off" */

export class TechSupportStatusOptions {
    constructor(
        public user: string,
        public comment: string
    ) { }
}

export class TechSupportJobOptions {
    constructor(
        public user: string,
        public comment: string,
        public jobId: number
    ) { }
}

export interface AnalysisRequest {
// Services model for tacking a job request on to a sample
// (Note: same format as JobOptions used in the post)
    pipelineId?: string
    analysisName?: string
    entryPoints?: DeferredJobOptionEntryPoint[]
    taskOptions?: JobTaskOption[]
    presetId?: string
}

export const addTaskOption = (jobOptions: JobOptions, newOption: JobTaskOption): JobOptions => {
    const newTOs = [...jobOptions.taskOptions]
    const currentOption = newTOs.find( option => option.id === newOption.id)
    if (currentOption) {
        currentOption.value = newOption.value
    } else {
        newTOs.push( newOption)
    }
    return Object.assign({},jobOptions, newTOs)
}

export const removeTaskOption = (jobOptions: JobOptions, optionId: string): JobOptions => {
    let newTOs = jobOptions.taskOptions.filter( option => option.id !== optionId)
    return Object.assign({},jobOptions, newTOs)
}
