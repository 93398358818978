import React, { Fragment, useMemo, FunctionComponent } from "react"
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Badge,
    DropdownItem
} from "reactstrap"
import { User } from "../../data/model/user-model"
import { isAuthorized } from "../ProtectedRoute"
import { useSelector } from "react-redux"
import { NavMenuProps } from "./NavMenuProps"
import { ApplicationState } from "../../core/types"
import { ConnectedRouterProps } from "react-router-redux"
import { AlarmsStatus, calcAlarmsStatus } from "../../data/stores/alarm/alarm-store.helper"
import { AlarmModel } from "../../data/model/alarm-model"
import { ModalShowNotifications } from "./notifications/ModalShowNotifications"
import { isApiV3 } from "src/data/api"

export type AllNavProps = NavMenuProps & ConnectedRouterProps<ApplicationState>


const badgeFactorsMapping = {
    about: ["needsChemistryBundleUpdate", "needsUiBundleUpdate"]
}

const getBadgeCount = (user: User, props: AllNavProps, badgeFactors?: Array<any>) => {
    if (!isAuthorized(props.user, "admin")) { return 0 }
    if (!badgeFactors) {
        badgeFactors = Object.values(badgeFactorsMapping).reduce((total, v) =>
            total.concat(v)
        )
    }
    return badgeFactors.reduce((total, v) => (props[v] ? total + 1 : total), 0)
}

export const UtilityNavMenu: FunctionComponent<AllNavProps> = (props: AllNavProps) => {

    const alarms = useSelector<ApplicationState,AlarmModel[]>( state => state.alarm.alarms )
    const clearedAlarmIds = useSelector<ApplicationState,string[]>( state => state.alarm.clearedAlarmIds)
    const error = useSelector<ApplicationState,Error>( state => state.alarm.error )

    const totalBadgeCount = getBadgeCount(props.user, props)
    const aboutBadgeCount = getBadgeCount(props.user, props, badgeFactorsMapping["about"])

    const navigateTo = path => {
        props.history.push(path)
    }

    const alarmsStatus: AlarmsStatus = useMemo( () => {
        return calcAlarmsStatus(alarms, clearedAlarmIds)
    }, [alarms, clearedAlarmIds] )

    return (
        <Fragment>
            {!error && alarmsStatus.alarmsCount >= 0 && (
                <UncontrolledDropdown nav inNavbar className="alarm-nav-menu">
                    <DropdownToggle nav role="button" id="alarmDropdown">
                        <ModalShowNotifications
                            className="modal-ag-grid-tall"
                        />
                    </DropdownToggle>
                </UncontrolledDropdown>
            )}
            { error && <UncontrolledDropdown nav inNavbar className="alarm-nav-menu">
                <DropdownToggle nav role="button" id="alarmDropdown">
                    { (error as any).status !== 403 &&
                        <b>Alarms Request Error: {(error as any)?.originalError?.message || "Request Failed"}</b>
                    }
                    { (error as any).status === 403 &&
                        <b>Alarms Request Error: Permissions Error</b>
                    }
                </DropdownToggle>
            </UncontrolledDropdown>}
            <UncontrolledDropdown nav inNavbar className="utility-nav-menu">
                <DropdownToggle nav role="button" id="utilityDropdown">
                    <span style={{"marginLeft": "2.5rem"}}>Settings</span>
                    {totalBadgeCount > 0 && (
                        <Badge pill color="danger" className="pb-badge-text">
                            {totalBadgeCount}
                        </Badge>
                    )}
                </DropdownToggle>
                <DropdownMenu aria-labelledby="utilityDropdown">
                    <DropdownItem
                        className="btn"
                        onClick={() => navigateTo("/settings/general")}
                    >
                        General
                    </DropdownItem>
                    <DropdownItem
                        className="btn"
                        onClick={() => navigateTo("/settings/instruments")}
                    >
                        Instrument Settings
                    </DropdownItem>
                    <DropdownItem
                        className="btn"
                        onClick={() => navigateTo("/settings/notifications")}
                    >
                        Notification Settings
                    </DropdownItem>
                    <DropdownItem
                        className="btn"
                        onClick={() => navigateTo("/settings/users")}
                    >
                        User Management
                    </DropdownItem>
                    { !isApiV3() &&
                        <DropdownItem
                            className="btn"
                            onClick={() => navigateTo("/settings/updates")}
                        >
                            Updates
                            {aboutBadgeCount > 0 && (
                                <Badge
                                    pill
                                    color="danger"
                                    className="cog-badge"
                                    style={{ marginLeft: 0 }}
                                >
                                    {aboutBadgeCount}
                                </Badge>
                            )}
                        </DropdownItem>
                    }
                    <DropdownItem
                        className="btn"
                        onClick={() => navigateTo("/settings/about")}
                    >
                        About
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment>
    )
}
