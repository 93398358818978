import { IHeaderParams, SortDirection } from "ag-grid-community"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { AVAILABLE_APPLICATION_NAMES } from "./AnalysisApplicationFilter"
import { AVAILABLE_STATES } from "./AnalysisStateFilter/AnalysisStateFilter"
import { ColumnCheckboxFilterModal } from "./ColumnCheckboxFilterModal"
import { ArrayIndicator } from "../../data/stores/analysis/analysis-selector/analysis-selector-actions"

export const SearchColumnHeader: FunctionComponent<IHeaderParams> = (props: IHeaderParams) => {
    const [sortOrder, setSortOrder] = useState(props.column.getSort())
    const [isFilterActive, setIsFilterActive] = useState(props.column.isFilterActive())
    const refButton = useRef(null)
    const columnId = props.column.getId()

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const filterModel = props.api.getFilterModel() || {}
    const selected = (filterModel[columnId] && filterModel[columnId].filter) || ""

    // Used only to trigger redraws; the "truth" is kept in the filterModel
    const [selected2, setSelected2] = useState(selected)

    const setSelected = (data) => {
        const filterToAdd = { [columnId]: { filterType: "text", type: "in", filter: data }}
        const newFilterModel = Object.assign({}, filterModel, filterToAdd)
        if (!data.length) {
            newFilterModel[columnId] = null
        }
        props.api.setFilterModel(newFilterModel)
        // Used to force a redraw
        setSelected2(data || selected2)
    }

    const isCheckboxFilterMenu = () => {
        return (["state" /*, "analysisApp" */].includes(columnId))
    }

    const getAvailableOptions = () => {
        if (columnId === "state") {
            return AVAILABLE_STATES
        } else if (columnId === "applicationName") {
            return AVAILABLE_APPLICATION_NAMES
        } else {
            return []
        }
    }

    const availableOptions = isCheckboxFilterMenu() ? getAvailableOptions() : []

    const toggleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const onMenuClicked = () => {
        if (isCheckboxFilterMenu()) {
            toggleMenuOpen()
        } else {
            props.showColumnMenu(refButton.current)
        }
    }

    const onSortChanged = () => {
        if (props.column.isSortAscending()) {
            setSortOrder("asc")
        } else if (props.column.isSortDescending()) {
            setSortOrder("desc")
        } else {
            setSortOrder(null)
        }
    }

    const onSortClicked = () => {
        let newSortOrder: SortDirection = null
        if (props.column.getColDef().sortable) {
            // Rotate sort order if sortable
            if (sortOrder === "asc") {
                newSortOrder = "desc"
            } else if (sortOrder === "desc") {
                newSortOrder = null
            } else {
                newSortOrder = "asc"
            }
        }
        setSortOrder(newSortOrder)
        props.setSort(newSortOrder, false)
    }

    useEffect(() => {
        props.column.addEventListener("sortChanged", onSortChanged)
        return () => props.column.removeEventListener("sortChanged", onSortChanged)
    })

    const onFilterChanged = () => {
        setIsFilterActive(props.column.isFilterActive())
    }

    useEffect(() => {
        props.column.addEventListener("filterChanged", onFilterChanged)
        return () => props.column.removeEventListener("filterChanged", onFilterChanged)
    })

    let menu = null;
    if (props.enableMenu) {
        menu =
            <div ref={refButton}
                 style={{display: "inline-block"}}
                 className={"customHeaderMenuButton" + (isFilterActive ? " active" : "") }
                 onClick={() => onMenuClicked()}>
                <i className="ag-icon ag-icon-filter"></i>
            </div>
    }

    function classForSortOrder() {
        if (sortOrder === "asc") {
            return " ag-header-label-icon ag-icon-asc mr-1"
        } else if (sortOrder === "desc") {
            return " ag-header-label-icon ag-icon-desc mr-1"
        } else {
            return " ag-header-label-icon ag-icon-none mr-1"
        }
    }

    let sort = null;
    if (props.enableSorting) {
        sort =
            <div onClick={() => onSortClicked()} onTouchEnd={() => onSortClicked()}
                style={{display: "inline-block"}}
                className={"customSort"}>
                {["asc", "desc"].includes(sortOrder)
                    ? <i className={"ag-icon" + classForSortOrder()} style={{height: "21px"}}></i>
                    : <i style={{marginLeft:"6px", marginRight:"5px", fontStyle: "normal"}}>⇵</i>}
            </div>
    }

    function selectedToArray(selected) {
        if (!selected) return []
        return selected.substring(ArrayIndicator.length).split(",")
    }

    return (
        <div style={{display: "inline-block"}}>
            <div
                className="customHeaderLabel"
                style={{display: "inline-block", position: "relative"}}
                onClick={() => onSortClicked()}>{props.displayName}</div>
            {sort}
            {menu}
            {columnId === "isChecked" && <input
                style={{width: "16px", height: "16px"}}
                type="checkbox"
                onClick={
                    event => {
                        if (event.target["checked"]) {
                            props.api.forEachNode(node => {
                                node.setSelected(true)
                            })
                        } else {
                            props.api.forEachNode(node => {
                                node.setSelected(false)
                            })
                        }
                    }
                }
                ></input>
            }
            {isCheckboxFilterMenu() &&
                <ColumnCheckboxFilterModal
                    id={columnId + "-filter"}
                    isOpen={isMenuOpen}
                    toggleModal={toggleMenuOpen}
                    onChange={data => setSelected(data)}
                    selected={selectedToArray(selected)}
                    parentRef={refButton as any}
                    availableOptions={availableOptions}
                ></ColumnCheckboxFilterModal>
            }
        </div>
    )
 }
