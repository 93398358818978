import { Reducer } from "redux"
import { SampleSetupActionTypes, SampleApiState } from "./sample-setup-api-types"

const initialState: SampleApiState = {
    loading: false,
    data: [],
    error: null
}

export const sampleReducer: Reducer<SampleApiState> = (state: SampleApiState = initialState, action: any) => {
    switch (action.type) {
        case SampleSetupActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case SampleSetupActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case SampleSetupActionTypes.GET_SAMPLE_LIST_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                data: action.payload
            })

        default:
            return state
    }
}
