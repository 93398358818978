import { Reducer } from "redux"
import { DevState, DevActionTypes } from "./dev-types"
import { appConfig } from "../../../core/config/app-config"

const initialState: DevState = {
    isDevModeEnabled: appConfig.isDevModeEnabled,
    isInternalModeEnabled: appConfig.isInternalModeEnabled,
    isAlphaModeEnabled: appConfig.isAlphaModeEnabled,
    isDebugFreeMode: false
}

export const devReducer: Reducer<DevState> = (state: DevState = initialState, action: any) => {
    switch (action.type) {
        case DevActionTypes.SET_IS_DEV_MODE_ENABLED:
            return Object.assign({}, state, {
                isDevModeEnabled: action.payload
            })
        case DevActionTypes.SET_IS_INTERNAL_MODE_ENABLED:
            return Object.assign({}, state, {
                isInternalModeEnabled: action.payload
            })
        case DevActionTypes.SET_IS_ALPHA_MODE_ENABLED:
            return Object.assign({}, state, {
                isAlphaModeEnabled: action.payload
            })
        case DevActionTypes.SET_IS_FORCE_FREE_MODE:
            return Object.assign({}, state, {
                isDebugFreeMode: action.payload
            })
        default:
            return state
    }
}
