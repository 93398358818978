import { Action, ActionCreator, Dispatch } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { ApiErrorResponse, ApiResponse } from "apisauce"
import { push } from "react-router-redux"

import {
    AuthActionTypes,
    LoginPayload,
    TokenResponse,
    UserResponse
} from "./login-types"
import * as API from "../../../data/api"
import { ApplicationState, ThunkResult } from "../../../core/types"
import { isApiV3, isVegaSupported, refreshService } from "../../../data/api"
import {
    getChemistryBundleCurrentVersion,
    checkForChemistryBundleUpgrade,
    getUiBundleCurrentVersion,
    checkForUiBundleUpgrade
} from "../../../data/stores/about/about-api-actions"
import { setInstrumentSupport } from "../../../data/stores/instrument/instrument-actions"
import { User, userFromUserResponse } from "../../../data/model/user-model"
import { fetchTenant, setTenant } from "src/data/stores/tenant/tenant-actions"

export const REDIRECT_STORAGE_KEY = "pb_login_redirect"

export const login = (payload: LoginPayload): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
        try {
            dispatch(apiAttempt())

            const newPayload: any = {
                ...payload,
                grant_type: "password",
                scope:
                    "welcome sample-setup runs instruments data-management analysis openid admin userinfo smrtlink-admin"
            }
            const response: ApiResponse<TokenResponse> = await API.getToken(newPayload)
            const { data: tokenData } = response

            if (response.ok) {
                API.setVersion(response.headers[API.API_VERSION_H])
                const tokenHeader = (!isApiV3() || payload.code)
                    ? tokenData.access_token
                    : tokenData.id_token

                // Set Token
                if (tokenData) {
                    dispatch(setToken(tokenData))
                    API.setTokenHeader(tokenData)
                    refreshService.setHasCachedRefreshToken(!!tokenData.refresh_token)
                }

                const statusResponse = isApiV3() ? {data: {apiFlags: {isSmrtLinkLite: false}}} : await API.slStatus()
                const slStatus = statusResponse.data
                const isLite = slStatus.apiFlags && slStatus.apiFlags.isSmrtLinkLite

                // Set login status
                localStorage.setItem("authStatus", "LOG_IN")

                const persistInstrument = JSON.parse(localStorage.getItem("persist:instrument")) || {
                    currentInstrumentType: null,
                    instrumentSupport: null
                }

                const instrumentSupport = isApiV3()
                    ? {supportsSequel2: false, supportsSequel2e: false, supportsRevio: true, supportsVega: isVegaSupported()}
                    : (JSON.parse(persistInstrument.instrumentSupport) || {})
                const currentInstrumentType = JSON.parse(persistInstrument.currentInstrumentType)

                dispatch(setInstrumentSupport(
                    {
                        supportsSequel: false,
                        supportsSequel2: isLite ? false : instrumentSupport.supportsSequel2,
                        supportsSequel2e: instrumentSupport.supportsSequel2e,
                        supportsRevio: instrumentSupport.supportsRevio,
                        supportsVega: isVegaSupported() ? instrumentSupport.supportsVega : false
                    }, currentInstrumentType)
                )

                // Get user info
                let userResponse: ApiResponse<UserResponse> = await API.aUser(isApiV3() ? tokenHeader : undefined)

                if (isApiV3()) {
                    const role = "Internal/" + userResponse["custom:pb_role"]
                    const username = userResponse["username"]
                    const email = userResponse["email"]
                    // TODO(pfernhout) Fix AWS Login kludge
                    userResponse = {ok: true, data: {
                        userId: username,
                        roles: [role],
                        email: email,
                        error: null,
                        error_description: "",
                        message: "message",
                        status: ""
                    }} as any
                }
                let { data } = userResponse
                if (!data && isApiV3()) {
                    data = userResponse as any
                }
                const user: User = userFromUserResponse(data)
                if (userResponse.ok || (isApiV3() && (userResponse as any).username)) {
                    dispatch(setUser(user))
                    dispatch(apiSuccess())
                    dispatch(fetchTenant())

                    // check bundle upgrade
                    if (user.roles.includes("Internal/PbAdmin")) {
                        dispatch(getChemistryBundleCurrentVersion())
                        dispatch(checkForChemistryBundleUpgrade())
                        if (!isApiV3()) {
                            dispatch(getUiBundleCurrentVersion())
                            dispatch(checkForUiBundleUpgrade())
                        }
                    }

                    if (payload.disableRedirect) {
                        API.refreshService.retryDeferredAPICalls()
                        return
                    }
                    let redirect = sessionStorage.getItem(REDIRECT_STORAGE_KEY) || "/"
                    if (redirect.endsWith("/login")) {
                        redirect = "/"
                    }
                    sessionStorage.removeItem(REDIRECT_STORAGE_KEY)
                    dispatch(push(redirect))
                } else {
                    dispatch(apiFailure((data && data.error_description) || "Login failure."))
                }
            } else {
                let errorMessage = "Login failure."
                if (tokenData) {
                    if (tokenData instanceof Error) {
                        errorMessage = tokenData.message || errorMessage
                    } else if (response.status === 408) {
                        errorMessage = tokenData.message || errorMessage
                    } else {
                        errorMessage = tokenData.error_description || errorMessage
                    }
                } else {
                    if (response.problem === "NETWORK_ERROR") {
                        errorMessage = "Login failed due to a network error."
                        if (response.config && response.config.baseURL) {
                            errorMessage += " Please check your access to: " + response.config.baseURL
                        }
                    }
                }
                dispatch(apiFailure(errorMessage))
            }
        } catch (err) {
            let errorMessage = "Login failed."

            if (! (API.isApiErrorResponse(err))) {
                throw(err)
            }

            let responseErr = err as ApiErrorResponse<any>
            if (responseErr.status === 511 && responseErr.config && responseErr.config.baseURL) {
                errorMessage += " Please check your access to: " + responseErr.config.baseURL
            }
            dispatch(apiFailure(errorMessage))
        }
    }

export const refreshToken = (callback: any): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>,
    getState: () => ApplicationState
) => {
    try {
        const { refresh_token } = getState().auth

        if (refresh_token) {
            const payload: any = {
                refresh_token
            }
            const response: ApiResponse<TokenResponse> = await API.getToken(payload)
            const { data: tokenData } = response

            if (response.ok) {
                // Fetching tokens
                dispatch(setToken(tokenData))
                // Set Token
                if (tokenData) {
                    dispatch(fetchTenant())
                    API.setTokenHeader(tokenData)
                    refreshService.setHasCachedRefreshToken(!!tokenData.refresh_token)
                }

                // Set login status
                localStorage.setItem("authStatus", "LOG_IN")

                callback()
            } else {
                // refresh failed; also clearing refresh token
                dispatch(clearToken())
                const err = new Error("Refresh failed")
                callback(err)
                throw err
            }
        } else {
            // no refresh token
            callback("did not try")
        }
    } catch (err) {
        dispatch(apiFailure("Login session expired"))
        const errAsAny = err as any
        errAsAny.name = "RefreshTokenError"
        callback(errAsAny)
    }

}

export const logout = (): ThunkResult<Promise<void>> => async (
    dispatch: Dispatch,
    getState: () => ApplicationState
) => {
    const { access_token } = getState().auth

    if (access_token) {
        dispatch(clearToken())
        dispatch(setUser(null))
        if (isApiV3()) await API.globalCognitoLogout(access_token)
        dispatch(setTenant(null))
        dispatch(push("/login"))

        // For dispatching an event to logout all windows
        localStorage.setItem("authStatus", "LOG_OUT")

        sessionStorage.clear()
    }
}

export const setToken: ActionCreator<Action> = (payload: TokenResponse) => ({
    type: AuthActionTypes.SET_TOKEN,
    payload
})

export const clearToken: ActionCreator<Action> = () => ({
    type: AuthActionTypes.CLEAR_TOKEN
})

export const setUser: ActionCreator<Action> = (payload: UserResponse) => ({
    type: AuthActionTypes.SET_USER,
    payload
})

export const setPendingAction: ActionCreator<Action> = (payload: any) => ({
    type: AuthActionTypes.SET_PENDING_ACTION,
    payload
})

export const apiAttempt: ActionCreator<Action> = () => ({
    type: AuthActionTypes.API_ATTEMPT
})

export const apiSuccess: ActionCreator<Action> = () => ({
    type: AuthActionTypes.API_SUCCESS
})

export const apiFailure: ActionCreator<Action> = (payload) => ({
    type: AuthActionTypes.API_FAILURE,
    payload
})

