export interface IStopper {
    isStopped: () => boolean
}

export interface IPollScheduler {
    start()
    cancel()
}

export interface IScheduledStopper extends IStopper, IPollScheduler {}

export class PollScheduler implements IPollScheduler {

    private isCancelled: boolean = false
    private name: string = "UNNAMED"
    private isRunning: boolean = false

    constructor (
        private task: () => void,
        private intervalsInSecs: number[],
        private stopper?: IStopper
    ) {}

    public start() {
        this.isCancelled = false
        this.doTask(0)
        this.isRunning = true
    }

    public cancel() {
        this.isCancelled = true
        this.isRunning = false
    }

    public getIsRunning() {
        return this.isRunning
    }
    
    private doTask(iter: number): void {
        if (this.isCancelled) {
            this.isRunning = false
            return
        }
        if (this.stopper && this.stopper.isStopped() ) {
            this.isRunning = false
            return
        }
        this.task()
        const interval = this.getIntervalInMs(iter)
        setTimeout( this.doTask.bind(this), interval, iter+1)
    }

    private  getIntervalInMs(iter: number): number {
        if (iter >=  this.intervalsInSecs.length) {
            return this.intervalsInSecs[this.intervalsInSecs.length-1] * 1000
        }

        return this.intervalsInSecs[iter] * 1000
    }

    public setName(name: string) {
        this.name = name
    }

    public wasCancelled():boolean {
        return this.isCancelled
    }
}
// --- Testing code below ---

export function testPollScheduler() {
    const scheduler: PollScheduler  = new PollScheduler(
        printTime,
        [1,3,5,10],
        new FixedTimeStopper(20)
    )
    scheduler.start()
}


export function printTime() {
    // console.log(new Date().toLocaleTimeString());
}

export class FixedTimeStopper {
    private startTime = new Date()

    constructor (
        private durationInSec: number
    ) {}

    public isStopped() {
        const now = new Date()
        now.setSeconds(now.getSeconds() - this.durationInSec)
        return now > this.startTime
    }
}
