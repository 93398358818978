import { NotificationModel } from "./notification-model"

export type NotificationSeverity = "INFO" | "WARNING" | "ERROR" | "CRITICAL"

const prefKeys = [
    {pref: "daysTillArchive", key: "smrtlink.notifications.days_till_archive" },
    {pref: "isByEmail", key: "smrtlink.notifications.is_by_email" },
    {pref: "emailAddress", key: "smrtlink.notifications.email_address" },
    {pref: "notifyOnUpdate", key: "smrtlink.notifications.notify_on_update" },
    {pref: "notifyOnWarning", key: "smrtlink.notifications.notify_on_warning" },
    {pref: "notifyOnError", key: "smrtlink.notifications.notify_on_error" },
    {pref: "includeInfos", key: "smrtlink.notifications.include_infos" },
    {pref: "includeWarnings", key: "smrtlink.notifications.include_warnings" },
    {pref: "includeErrors", key: "smrtlink.notifications.include_errors" },
    {pref: "includeCriticals", key: "smrtlink.notifications.include_criticals" }
]
export interface UserPreferences {
    notificationPreferences?: NotificationsPreferences
}

export interface NotificationsPreferences {
    daysTillArchive?: number
    isByEmail?: boolean
    emailAddress?: string
    notifyOnUpdate?: boolean
    notifyOnWarning?: boolean
    notifyOnError?: boolean
    includeInfos?: boolean
    includeWarnings?: boolean
    includeErrors?: boolean
    includeCriticals?: boolean
}

export const defaultUserPreference: UserPreferences = {
    notificationPreferences: {
        daysTillArchive: 14,
        isByEmail: false,
        emailAddress: null,
        notifyOnUpdate: false,
        notifyOnWarning: false,
        notifyOnError: false,
        includeInfos: true,
        includeWarnings: true,
        includeErrors: true,
        includeCriticals: true
    }
}

type UserPreferencesJson = {
    key: string
    value: any
}[]

export const jsonToUserPreferences = (json: UserPreferencesJson): UserPreferences => {
    const notificationPrefs = {}
    for (let keyValue of json) {
        const {key, value} = keyValue
            const prefKey = prefKeys.find( prefKey => prefKey.key === key)
            if (prefKey) {
                notificationPrefs[prefKey.pref] = value
            }
    }
    const userPreferences: UserPreferences = {}
    if (Object.keys(notificationPrefs).length > 0) {
        userPreferences.notificationPreferences = notificationPrefs
    }
    return userPreferences
}

export const userPreferencesToJson = (prefs: UserPreferences): UserPreferencesJson => {
    const json = []

    const notificationPrefs = prefs.notificationPreferences
    if (notificationPrefs) {
        for (let prefKey of prefKeys) {
            const { pref, key} = prefKey
            if (pref in notificationPrefs) {
                json.push( {
                    key: key,
                    value: notificationPrefs[pref]
                })
            }
        }
    }
    return json
}

export const includeNotification = (notification: NotificationModel, prefs: NotificationsPreferences):boolean => {
    if (prefs) {
        switch( notification.severity) {
            // update this when there is more clarity from mkting
            case "INFO":
                if ( !prefs.includeInfos ) return false
                break
            case "WARNING":
                if ( !prefs.includeWarnings ) return false
                break
            case "ERROR":
                if ( !prefs.includeErrors ) return false
                break
            case "CRITICAL":
                if ( !prefs.includeCriticals ) return false
                break
            default: 
        }
        return true //unreachable, but needed to prevent "not all code paths return" lint error.
    } else {
        return true
    }
   
}