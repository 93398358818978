import { Store, createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
import { routerMiddleware } from "connected-react-router"
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from "@redux-devtools/extension"
// If you use react-router, don't forget to pass in your history type.
import { History } from "history"
// Redux persist
import { persistStore, Persistor } from "redux-persist"

import { ApplicationState } from "./types"
import { createRootReducer } from "./root-reducer"
import { setTokenHeader, refreshService } from "../data/api"

export interface StoreConfig {
    store: Store<ApplicationState>
    persistor: Persistor
}

export function configureStore(history: History, initialState: ApplicationState): StoreConfig {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({})

    // We'll create our store with the combined reducers and thunk, and the initial Redux state that
    // we'll be passing from our entry point.
    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
    )

    // Create persistor & rehydrate action configuration
    const persistor = persistStore(store, undefined, () => {
        const { auth } = store.getState()
        // This is also done earlier in login-reducer authReducer persist/REHYDRATE
        // to workaround condition where components can launch API requests before Rehydration is complete.
        setTokenHeader(auth)
        refreshService.setHasCachedRefreshToken(!!auth.refresh_token)
    })

    return { store, persistor }
}
