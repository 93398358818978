// TODO: update this to use an enum if a more straightforward definition of the type
//  is required. However TS Enums can't merge with type definitions so would need unique declarations
export const InstrumentType = {
    Sequel: "Sequel",
    Sequel2: "Sequel2",
    Sequel2e: "Sequel2e",
    Revio: "Revio",
    Vega: "Vega"
} as const
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
export type InstrumentType = (typeof InstrumentType)[keyof typeof InstrumentType]
export type ChipType = "1mChip" | "8mChip" | "AllChips" | "25mChip"

export const isRevio = (s: string, flags?: {isPermissive: boolean}): boolean => {
    if (s === "Revio") return true
    if (flags && flags.isPermissive) {
        if (s === "revio") return true
    }
    return false
}

export const isSequel = (s: string, flags?: {isPermissive: boolean}): boolean => {
    if (s === "Sequel") return true
    if (flags && flags.isPermissive) {
        if (s === "sequel") return true
    }
    return false
}

export const isSequel2 = (s: string, flags?: {isPermissive: boolean}): boolean => {
    if (s === "Sequel2") return true
    if (flags && flags.isPermissive) {
        if (s === "sequel2") return true
        if (s === "SequelII") return true
        if (s === "sequelII") return true
        if (s === "sequel II") return true
    }
    return false
}

export const isSequel2e = (s: string, flags?: {isPermissive: boolean}): boolean => {
    if (s === "Sequel2e") return true
    if (flags && flags.isPermissive) {
        if (s === "sequel2e") return true
        if (s === "SequelIIe") return true
        if (s === "sequelIIe") return true
        if (s === "sequel IIe") return true
        if (s === "Sequellie") return true
        if (s === "sequellie") return true
    }
    return false
}

export const isVega = (s: string): boolean => {
    return s === "Vega"
}

export const isRevioOrVega = (s: string, flags?: {isPermissive: boolean}): boolean => {
    return isVega(s) || isRevio(s, flags)
}

// Different sequel system settings often grouped together in code. Standardize on the same grouped label.
export const standardizeSystemName = (systemName: string): InstrumentType => {
    if (isRevioOrVega(systemName)) {
        return InstrumentType.Revio
    } else {
        return InstrumentType.Sequel2
    }
}

export const isSameInstrumentType = ( s1: string, s2: string, flags?: {isPermissive: boolean}) => {
    if (isSequel(s1, flags) && isSequel(s2, flags)) return true
    if (isSequel2(s1, flags) && isSequel2(s2, flags)) return true
    if (isSequel2e(s1, flags) && isSequel2e(s2, flags)) return true
    if (isRevioOrVega(s1, flags) && isRevioOrVega(s2, flags)) return true
    return false
}

export const InstrumentTypeToCorrectedTypeMap = {
    "Sequel": "Sequel",
    "Sequel2": "Sequel2",
    "Sequel2e": "Sequel2e",
    "Revio": "Revio",
    "SequelII": "Sequel2",
    "Sequel II": "Sequel2",
    "SequelIIe": "Sequel2e",
    "Sequel IIe": "Sequel2e",
    "Sequellie": "Sequel2e", // combat excel spell-corrector in CSV files
    "sequel": "Sequel",
    "sequel2": "Sequel2",
    "sequel2e": "Sequel2e",
    "revio": "Revio",
    "sequelII": "Sequel2",
    "sequel II": "Sequel2",
    "sequelIIe": "Sequel2e",
    "sequel IIe": "Sequel2e",
    "sequellie": "Sequel2e", // combat excel spell-corrector in CSV files
}

export const InstrumentTypeToDisplayNameMap = {
    Sequel: "Sequel",
    Sequel2: "Sequel II",
    Sequel2e: "Sequel IIe",
    Revio: "Revio",
    Vega: "Vega"
}

const InstrumentTypeToChipTypeMap = {
    Sequel: "1mChip",
    Sequel2: "8mChip",
    Sequel2e: "8mChip",
    Revio: "25mChip",
    Vega: "25mChip"
}
const ChipTypeToDefaultInstrumentTypeMap = {
    "1mChip": "Sequel",
    "8mChip": "Sequel2",
    "25mChip": "Revio"
}

export function getDisplayNameForInstrumentType(instrumentType: InstrumentType) {
    const result = InstrumentTypeToDisplayNameMap[instrumentType]
    if (!result) { throw new Error("getDisplayNameForInstrumentType: Unexpected instrument type: " + instrumentType) }
    return result
}

export function getChipTypeForInstrumentType(instrumentType: InstrumentType): ChipType {
    const result = InstrumentTypeToChipTypeMap[instrumentType]
    if (!result) { throw new Error("getChipTypeForInstrumentType: Unexpected instrument type: " + instrumentType) }
    return result as ChipType
}


// This function is only for when the instrument type is not know and a legacy mapping can be assumed
export function getDefaultInstrumentTypeForChipType(chipType: ChipType): InstrumentType {
    const result = ChipTypeToDefaultInstrumentTypeMap[chipType]
    if (!result) {
        // Assume old legacy item and so use sequel
        return "Sequel"
    }
    return result
}

export const instrumentTypeBySerial = (serial: string): InstrumentType => {
    if (serial.startsWith("5")) {
        if (serial.endsWith("e")) return "Sequel2e"
        if (serial.endsWith("U")) return "Sequel2"
        return "Sequel"
    }
    if (serial.startsWith("6")) {
        if (serial.endsWith("e")) return "Sequel2e"
        return "Sequel2"
    }
    if (serial.startsWith("8")) {
        return "Revio"
    }
    return null
}
