import { DataType } from "../../../model/data-type"
import { JobPrefillInfo } from "../../../services/job-copy-service"
import { BatchMode } from "../../../model/analysis-settings-constants"
import { Dataset } from "../../../model/dataset-model"

export const SPINNER_MAX = 384
export const NewAnalysisTypes = {

  SET_STEP_ONE_SELECTIONS: "@@new-analysis/SET_STEP_ONE_SELECTIONS",
  RESET_STEP_ONE_SELECTIONS: "@@new-analysis/RESET_STEP_ONE_SELECTIONS",
  SET_IS_SUBMITTING_JOB:  "@@new-analysis/SET_IS_SUBMITTING_JOB",
  SET_NUM_JOBS_SUBMITTED:  "@@new-analysis/SET_NUM_JOBS_SUBMITTED",

  API_ATTEMPT: "@@new-analysis/API_ATTEMPT",
  API_SUCCESS: "@@new-analysis/API_SUCCESS",
  API_FAILURE: "@@new-analysis/API_FAILURE"
}

export type NewAnalysisState = {
    stepOne: StepOneSelections
    isSubmittingJob: boolean
    numJobsSubmitted: number
}

export interface StepOneSelections {
    analysisName: string
    dataType: DataType
    canSelectDataType: boolean
    batchMode: string
    cameFrom: string
    selectedParentUuid: string | null
    selectedDatasets: Dataset[] // coming from step 1
    jobPrefill: JobPrefillInfo
    activeDatasetIndex: number
    pipelineId: string
    isLoaded: boolean
}
export const isAnalysisStateBatchCustom = (stepOne: StepOneSelections): boolean => {
    return stepOne.selectedDatasets!.length > 1 && stepOne.batchMode === BatchMode.Custom
}

export const isAnalysisStateBatchIdentical = (stepOne: StepOneSelections): boolean => {
    const isMulti = stepOne.selectedDatasets!.length > 1
    // Note: "Identical" is default for stepOne.batchMode
    return isMulti && stepOne.batchMode === BatchMode.Identical
}
