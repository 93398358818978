export const DevActionTypes = {
  SET_IS_DEV_MODE_ENABLED: "@@dev/SET_IS_DEV_MODE_ENABLED",
  SET_IS_INTERNAL_MODE_ENABLED: "@@dev/SET_IS_INTERNAL_MODE_ENABLED",
  SET_IS_ALPHA_MODE_ENABLED: "@@dev/SET_IS_ALPHA_MODE_ENABLED",
  SET_IS_FORCE_FREE_MODE: "@@dev/SET_IS_FORCE_FREE_MODE"
}

export interface DevState {
  readonly isDevModeEnabled: boolean
  readonly isInternalModeEnabled: boolean
  readonly isAlphaModeEnabled: boolean
  readonly isDebugFreeMode: boolean
}
