import { PipelineTemplate, PipelineTemplateMap } from "../model/pipeline-template-models"
import * as API from "../api"
import { PipelineDataStoreViewRule } from "../model/pipeline-datastore-view-rules-model"
import { ReportViewRule } from "../model/report-view-rules.model"
import { ReportNamesMap } from "./job-reports-service"
import * as jobReportsService from "./job-reports-service"
import {ReportFormatMap} from "./job-reports-service"
import { PipelineViewRule, ConcretePipelineViewRule } from "../model/pipeline-template-view-rules-model"
import * as _ from "lodash"
import { WorkflowPreset } from "../model/workflow-preset-model"
import { DatastoreFile } from "../model/datastore-file-model"
import { Dataset } from "../model/dataset-model"
import { SmrtLinkStatus } from "../model/status-model"
import { ApplicationsResponse, DesignModel } from "../model/run-model"
import { getDesign } from "./run-service"
import { SampleSetupApplications } from "../../silos/sample-setup/utils/binding-calculator/sample-calculator-params-model"
import DEBUG_LOCAL_PARTS_XML from "./debug-chem-bundle/PacBioAutomationConstraints.xml"
// The following flag is for development only and should be set to false for production
const DEBUG_USE_LOCAL_PARTS_XML = false

export class ApiCache {

    // Promises are used here to cache a returned value.
    // Many callers can use the same promise to retrieve a result.
    private pipelineTemplatesPromise: Promise<PipelineTemplate[]> | null = null
    private pipelineViewRulesPromise: Promise<PipelineViewRule[]> | null = null
    private pipelineDatastoreViewRulesPromise: Promise<PipelineDataStoreViewRule[]> | null = null
    private reportViewRulesPromise: Promise<ReportViewRule[]> | null = null
    private reportNamesMapPromise: Promise<ReportNamesMap> | null = null
    private reportFormatMapPromise: Promise<ReportFormatMap> | null = null
    private workflowPresetsPromise: Promise<WorkflowPreset[]> | null = null

    private datastoreFileArrayPromises: { [index: string]: Promise<DatastoreFile[]> } = { }
    private datastoreFileDownloadPromises: { [index: string]: Promise<string> } = { }
    private datasetPromises: { [index: string]: Promise<Dataset> } = { }
    private runDesignPromises: { [index: string]: Promise<DesignModel> } = { }

    private slStatusPromise: Promise<SmrtLinkStatus> | null = null

    private sampleSetupApplicationParamsPromise: Promise<SampleSetupApplications> | null = null

    private automationConstraintsPromise: Promise<string> | null = null

    private runDesignDefaultsPromise: Promise<ApplicationsResponse> | null = null

    private appConfigOverridesPromise: Promise<any> | null = null

    private specialCaseDatasets: { [uuid: string]: Dataset } = {}

    public getPipelineTemplatesMap(): Promise<PipelineTemplateMap> {
        return this.getPipelineTemplates().then( pipelines => {
            const templatesMap: PipelineTemplateMap = {}
            for (let pipeline of pipelines) {
                templatesMap[pipeline.id] = pipeline
            }
            return templatesMap
        })
    }

    public getPipelineTemplates(): Promise<PipelineTemplate[]> {
        if (this.pipelineTemplatesPromise === null) {
            this.pipelineTemplatesPromise = this.loadPipelineTemplates()
        }
        return this.pipelineTemplatesPromise
    }

    private loadPipelineTemplates(): Promise<PipelineTemplate[]> {
        return API.nPipelineTemplates().then( response => {
            return _.sortBy(response.data, ["name"], ["asc"])
        })
    }

    public getPipelineViewRules(): Promise<PipelineViewRule[]> {
        if (this.pipelineViewRulesPromise === null) {
            this.pipelineViewRulesPromise = this.loadPipelineViewRules()
        }
        return this.pipelineViewRulesPromise
    }

    private loadPipelineViewRules(): Promise<PipelineViewRule[]> {
        return API.nPipelineViewRules().then( response => {
            return response.data.map( rule => new ConcretePipelineViewRule(rule))
        })
    }

    public getPipelineDatastoreViewRules(): Promise<PipelineDataStoreViewRule[]> {
        if (this.pipelineDatastoreViewRulesPromise === null) {
            this.pipelineDatastoreViewRulesPromise = this.loadPipelineDatastoreViewRules()
        }
        return this.pipelineDatastoreViewRulesPromise
    }

    private loadPipelineDatastoreViewRules(): Promise<PipelineDataStoreViewRule[]> {
        return API.nDatastoreViewRules().then( response => {
            return response.data
        })
    }

    public getReportViewRules(): Promise<ReportViewRule[]> {
        if (this.reportViewRulesPromise === null) {
            this.reportViewRulesPromise = this.loadReportViewRules()
        }
        return this.reportViewRulesPromise
    }

    private loadReportViewRules(): Promise<ReportViewRule[]> {
        return API.nReportViewRules().then( response => {
            return response.data
        })
    }

    public getReportNamesMap(): Promise<ReportNamesMap> {
        if (this.reportNamesMapPromise === null) {
            this.reportNamesMapPromise = this.loadReportNamesMap()
        }
        return this.reportNamesMapPromise
    }

    private loadReportNamesMap(): Promise<ReportNamesMap> {
        return jobReportsService.getReportNames().then( reportNamesMap => {
            return reportNamesMap
        })
    }

    public getReportFormatMap(): Promise<ReportFormatMap> {
        if (this.reportFormatMapPromise === null) {
            this.reportFormatMapPromise = this.loadReportFormatMap()
        }
        return this.reportFormatMapPromise
    }

    private loadReportFormatMap(): Promise<ReportFormatMap> {
        return jobReportsService.getReportRules().then( reportRules => {
            return reportRules
        })
    }

    public getWorkflowPresets(): Promise<WorkflowPreset[]> {
        if (this.workflowPresetsPromise === null) {
            this.workflowPresetsPromise = this.loadWorkflowPresets()
        }
        return this.workflowPresetsPromise
    }

    private loadWorkflowPresets(): Promise<WorkflowPreset[]> {
        return API.nWorkflowPresets().then( response => {
            return response.data
        })
    }

    public getJobIgvFiles(jobId: number, jobType: string): Promise<DatastoreFile[]> {
        const key = JSON.stringify({jobId, jobType, type: "igv"})
        if (!this.datastoreFileArrayPromises[key]) {
            this.datastoreFileArrayPromises[key] = this.loadJobIgvFiles(jobId, jobType)
        }
        return this.datastoreFileArrayPromises[key]
    }

    private loadJobIgvFiles(jobId: number, jobType: string): Promise<DatastoreFile[]> {
        return API.nJobIgvFiles(jobId, jobType).then( response => {
            return response.data
        })
    }

    public getDatastoreFileDownload(uuid: string): Promise<string> {
        const key = uuid
        if (!this.datastoreFileDownloadPromises[key]) {
            this.datastoreFileDownloadPromises[key] = this.loadDatastoreFileDownload(uuid)
        }
        return this.datastoreFileDownloadPromises[key]
    }

    private loadDatastoreFileDownload(uuid: string): Promise<string> {
        return API.aDatastoreFileDownload(uuid).then( response => {
            return response.data
        })
    }

    public getDataset(uuid: string): Promise<Dataset> {
        const key = uuid
        if (!this.datasetPromises[key]) {
            this.datasetPromises[key] = this.loadDataset(uuid)
        }
        return this.datasetPromises[key]
    }

    private loadDataset(uuid: string): Promise<Dataset> {
        return API.anyDatasetByUuid(uuid).then( response => {
            return response.data
        })
    }

    // use at your own risk:  RunDesigns are frequently updated.
    public getRunDesign(runId: string): Promise<DesignModel> {
        const key = runId
        if (!this.runDesignPromises[key]) {
            this.runDesignPromises[key] = getDesign(runId)
        }
        return this.runDesignPromises[key]
    }

    public getSlStatus(options?: {forceRefresh: boolean}): Promise<SmrtLinkStatus> {
        if (!this.slStatusPromise || (options && options.forceRefresh)) {
            this.slStatusPromise = this.loadSlStatus()
        }
        return this.slStatusPromise
    }

    private loadSlStatus(): Promise<SmrtLinkStatus> {
        return API.slStatus().then( response => response.data)
        // --- Debugging:
        // return API.slStatus().then( response => {
        //     const status = response.data
        //     status.apiFlags.isSmrtLinkLite = true
        //     return status
        // })
    }

    getSampleSetupApplicationParams(): Promise<SampleSetupApplications> {
        if (!this.sampleSetupApplicationParamsPromise) {
            this.sampleSetupApplicationParamsPromise = this.loadSampleSetupApplicationParams()
        }
        return this.sampleSetupApplicationParamsPromise
    }

    private loadSampleSetupApplicationParams(): Promise<SampleSetupApplications> {
        return API.sampleSetupApplicationParams().then(response => response.data)
    }

    getAutomationContraints(): Promise<string> {
        if (!this.automationConstraintsPromise) {
            if (DEBUG_USE_LOCAL_PARTS_XML) {
                this.automationConstraintsPromise = fetch(DEBUG_LOCAL_PARTS_XML).then(response => response.text())
            } else {
                this.automationConstraintsPromise = API.automationConstraints().then(response => response.data)
            }
        }
        return this.automationConstraintsPromise
    }

    getRunDesignDefaults(): Promise<ApplicationsResponse> {
        if (!this.runDesignDefaultsPromise) {
            this.runDesignDefaultsPromise = API.runDesignDefaults().then(response => response.data)
        }
        return this.runDesignDefaultsPromise
    }

    getAppConfigOverrides() : Promise<any> {
        if (!this.appConfigOverridesPromise) {
            this.appConfigOverridesPromise = API.appConfigOverrides().then(response => response.data)
        }
        return this.appConfigOverridesPromise
    }
}

export const apiCache = new ApiCache()
