import React, {FunctionComponent, useEffect, useState} from "react"
import { apiCache } from "../../data/services/api-cache"
import { PollScheduler } from "../../core/utils/poll-scheduler"
import { formatApiErrorResponse } from "src/data/api"

const SL_STATUS_POLL_INTERVAL = 15 * 60
let slStatusPoller: PollScheduler | null = null  

export const SiteWideBanner: FunctionComponent= () => {

    const [alertMessage, setAlertMessage] = useState<string>("")

    useEffect(() => {
        if (slStatusPoller !== null) {
            if (!slStatusPoller.getIsRunning()) {
               slStatusPoller.start() 
            }
            else { /* it's already running. do nothing. */ }
        } else {
            slStatusPoller = new PollScheduler( () => {
                apiCache.getSlStatus({forceRefresh: true}).then(
                    slStatus => {
                        setAlertMessage(slStatus.alertMessage)
                    },
                    (error) => {
                        setAlertMessage(`Error connecting to SMRTLink Server: ${formatApiErrorResponse(error as any)}`)
                    }
                )
            }, [SL_STATUS_POLL_INTERVAL])
            slStatusPoller.start() 
        }
        return () => slStatusPoller.cancel()
    }, [])
    
    if (alertMessage) {
        return (
            <div className="full-width-banner" style={{height: "100%"}}> 
                <div className="pt-1 pb-1" style={{textAlign: "center"}}>
                    <span style={{color:"white", paddingLeft: "4rem", fontSize:"1.5rem"}}>
                        {alertMessage}
                    </span>
                </div>
            </div>  
        )    
    }
    return null
}

